import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useFetch, CachePolicies } from "use-http";
import isFunction from "lodash/isFunction";
import getApiUrl from "../utils/getApiUrl";
import {ILevel, ILevelActivity, ILevelApiResponse, IPrizeDraw, IPrizeDrawApiResponse, IUser} from "../types";
import {LEVEL_CODES, LEVELS, PRIZE_DRAWS} from "../pages/Program/ProgramConfig";
import {OPT_IN_STATUS, STATUSES, WIN_STATUS} from "../pages/Program/Constants";

export interface IUseUserResult {
  getUser: () => void;
  loading: boolean;
  setUser: (u: SetStateAction<TNullableUser>) => void;
  user: TNullableUser;
  getTotalConfigActivities: () => number;
  getTotalCompletedActivities: () => number;
  getStatusPercent: () => number;
  getUsername: () => string;
  getLevelData: (c:string) => ILevelApiResponse|null;
  getLevelPercent: (c: string) => number;
  getLevelConfig: (c:string) => ILevel|null;
  getPrizeDrawConfig: (c:string) => IPrizeDraw|null;
  getLevelStatus: (c:string, prevLevelStatus?: string, startDateOccurred?: boolean) => string;
  getLevelDrawOptInStatus: (c:string) => string;
  getPrizeDrawOptInStatus: (c:string) => string;
  hasWonLevelPrize: (c:string) => string;
  hasWonPrizeDraw: (c:string) => string;
  getLevelCompletedActivities: (c:string) => string[];
  getLevelSurveyActivities: (c:string) => ILevelActivity[];
  isRewardsActive: () => boolean;
  areAllLevelsCompleted: () => boolean;
}

type TNullableUser = IUser | null;

let user: TNullableUser;
let observers: Dispatch<SetStateAction<TNullableUser>>[] = [];

// Changes global user state and updates all observers
export const setUser = (u: SetStateAction<TNullableUser>) => {
  user = isFunction(u) ? u(user) : u;
  observers.forEach((update) => update(u));
};

let isInitial: boolean = true;
const useUser = (): IUseUserResult => {
  const [stateUser, setStateUser] = useState<TNullableUser>(user);

  const { get, loading, response } = useFetch<TNullableUser>(getApiUrl("partnermarketing_user_get_logged_in_api"), {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const getUser = useCallback(async () => {
      const data = await get();
      setUser(response.ok ? data : null);
  }, [get, response]);

  const getIRPData = useCallback(():ILevelApiResponse[] => {
    const fields = stateUser?.fields.filter((field):boolean => field.fieldName === 'IRP');
    const field = fields?.length ? fields[0] : null;
    if (field && field.value) {
      try {
        return JSON.parse(field.value);
      } catch (e) {
        throw Object.assign(
          new Error(`Cannot parse UserField value JSON: ${field.value}`),
          { code: 422 }
        );
      }
    }

    return [];
  }, [stateUser]);

  const getIRPPrizeDrawData = useCallback(():IPrizeDrawApiResponse[] => {
    const fields = stateUser?.fields.filter((field):boolean => field.fieldName === 'IRP_PRIZE_DRAW');
    const field = fields?.length ? fields[0] : null;
    if (field && field.value) {
      try {
        return JSON.parse(field.value);
      } catch (e) {
        throw Object.assign(
          new Error(`Cannot parse UserField value JSON: ${field.value}`),
          { code: 422 }
        );
      }
    }

    return [];
  }, [stateUser]);

  const getTotalConfigActivities = useCallback(() => {
    return Object.keys(LEVELS).reduce((accum, key) => {
      // @ts-ignore
      return accum + LEVELS[key].activities.length;
    }, 0);
  }, []);

  const getLevelData = useCallback((code:string):ILevelApiResponse|null => {
    let foundLevels: (ILevelApiResponse | null)[] = [];
    const levels = getIRPData();
    if (levels && levels.length) {
      foundLevels = levels.filter((_l: ILevelApiResponse) => {
        return _l.code === code;
      });
    }

    // @ts-ignore
    return foundLevels && foundLevels.length ? foundLevels.pop() : null;
  }, [getIRPData]);

  const getPrizeDrawData = useCallback((code:string):IPrizeDrawApiResponse|null => {
    let foundDraws: (IPrizeDrawApiResponse | null)[] = [];
    const draws = getIRPPrizeDrawData();
    if (draws && draws.length) {
      foundDraws = draws.filter((_l: IPrizeDrawApiResponse) => {
        return _l.code === code;
      });
    }

    // @ts-ignore
    return foundDraws && foundDraws.length ? foundDraws.pop() : null;
  }, [getIRPPrizeDrawData]);

  const getLevelConfig = useCallback((code:string):ILevel|null => {
    // @ts-ignore
    return LEVELS[code];
  }, []);

  const getPrizeDrawConfig = useCallback((code:string):IPrizeDraw|null => {
    // @ts-ignore
    return PRIZE_DRAWS[code];
  }, []);

  const getLevelCompletedActivities = useCallback((code: string):string[] => {
    // @ts-ignore
    if (!LEVELS[code] || !LEVELS[code].active) {
      return [];
    }

    const currentLevel = getLevelData(code);
    if (currentLevel) {
      const levelConfig = getLevelConfig(code);
      return currentLevel.completedActivities.filter(_a => {
        return levelConfig?.activities.reduce((accum, currentConfigActivity) => {
          return accum || currentConfigActivity?.assetId === _a || currentConfigActivity?.url === _a;
        }, false);
      });
    }

    return [];
  }, [getLevelData, getLevelConfig]);

  const getLevelSurveyActivities = useCallback((code: string):ILevelActivity[] => {
    // @ts-ignore
    if (!LEVELS[code] || !LEVELS[code].active) {
      return [];
    }

    const levelConfig = getLevelConfig(code);
    if (!levelConfig) {
      return [];
    }

    return levelConfig.activities.filter(_a => {
      return _a.isSurvey;
    });
  }, [getLevelConfig]);

  const getTotalCompletedActivities = useCallback(():number => {
    let completedActivities = 0;
    Object.values(LEVEL_CODES).forEach((code:string) => {
      const completed = getLevelCompletedActivities(code);
      completedActivities += completed.length;
    });

    return completedActivities;
  }, [getLevelCompletedActivities]);

  const getStatusPercent = useCallback(():number   => {
    const activitiesCount = getTotalConfigActivities();
    const completedActivities = getTotalCompletedActivities();

    const p = activitiesCount > 0 ? completedActivities / activitiesCount * 100 : 0;
    return Math.floor(p);
  }, [getTotalConfigActivities, getTotalCompletedActivities]);

  const getLevelPercent  = useCallback((code:string):number => {
    const data = getLevelData(code);
    const config = getLevelConfig(code);
    const percent = config?.activities && data?.completedActivities ?
      data?.completedActivities.length / config?.activities.length * 100
      : 0;

    return Math.floor(percent);
  }, [getLevelData, getLevelConfig]);

  const getLevelStatus = useCallback((code: string, prevLevelStatus:string = STATUSES.COMPLETED, startDateOccurred: boolean = true):string => {
    // @ts-ignore
    if (!LEVELS[code] || !LEVELS[code].active || prevLevelStatus !== STATUSES.COMPLETED || !startDateOccurred) {
      return STATUSES.SOON;
    }
    const completedActivities = getLevelCompletedActivities(code);
    // @ts-ignore
    if (completedActivities.length >= LEVELS[code].activities.length) {
      return STATUSES.COMPLETED;
    }

    if (completedActivities.length === 0) {
      return STATUSES.PENDING;
    }

    return STATUSES.IN_PROGRESS;
  }, [getLevelCompletedActivities]);

  const getLevelDrawOptInStatus = useCallback((code: string):string => {
    const data = getLevelData(code);
    return data?.inPrizeDraw ?? OPT_IN_STATUS.UNKNOWN;
  }, [getLevelData]);

  const getPrizeDrawOptInStatus = useCallback((code: string):string => {
    const data = getPrizeDrawData(code);
    return data?.inPrizeDraw ?? OPT_IN_STATUS.UNKNOWN;
  }, [getPrizeDrawData]);

  const hasWonLevelPrize = useCallback((code: string):string => {
    const data = getLevelData(code);
    return data?.hasWon ?? WIN_STATUS.UNKNOWN;
  }, [getLevelData]);

  const hasWonPrizeDraw = useCallback((code: string):string => {
    const data = getPrizeDrawData(code);
    return data?.hasWon ?? WIN_STATUS.UNKNOWN;
  }, [getPrizeDrawData]);

  const isRewardsActive = useCallback(():boolean => {
    return Object.keys(LEVELS).reduce((accum, currentCode) => {
      return accum || getLevelStatus(currentCode) === STATUSES.COMPLETED;
    }, false);
  }, [getLevelStatus]);

  const areAllLevelsCompleted = useCallback(():boolean => {
    return Object.keys(LEVELS).reduce((accum, currentCode) => {
      return accum && getLevelStatus(currentCode) === STATUSES.COMPLETED;
    }, true);
  }, [getLevelStatus]);

  const getUsername = useCallback(():string => {
    return stateUser?.firstName ?? '';
  }, [stateUser]);

  useEffect(() => {
    observers.push(setStateUser);
    setStateUser(user);

    return () => {
      observers = observers.filter((update) => update !== setStateUser);
    };
  }, [setStateUser]);

  useEffect(() => {
    if (!user && isInitial) {
      isInitial = false;
      getUser();
    }
  }, [getUser]);

  return {
    user: stateUser,
    loading: isInitial || loading,
    getUser,
    setUser,
    getUsername,
    getTotalConfigActivities,
    getTotalCompletedActivities,
    getStatusPercent,
    getLevelData,
    getLevelPercent,
    getLevelConfig,
    getPrizeDrawConfig,
    getLevelStatus,
    getLevelDrawOptInStatus,
    getPrizeDrawOptInStatus,
    hasWonLevelPrize,
    hasWonPrizeDraw,
    getLevelCompletedActivities,
    getLevelSurveyActivities,
    isRewardsActive,
    areAllLevelsCompleted,
  };
};

export default useUser;
