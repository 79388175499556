import qs from "qs";
import { DEFAULT_API_HOST } from "../constants";

const ROUTES = {
  partnermarketing_user_get_logged_in_api: "/user/me",
  partnermarketing_irp_track_asset_download: "/irp/track-asset-download/{id}",
  partnermarketing_irp_link_visit: "/irp/track-link-visit",
  partnermarketing_user_get_one_time_auth_token: "/user/me/one-time-auth-token",
  partnermarketing_irp_opt_in_prize_draw: "/irp/opt-in-prize-draw/{code}/{status}",
  partnermarketing_irp_survey: "/irp/survey",
  partnermarketing_irp_video: "/irp/video",
};

export default function useApiUrl(slug: string, slugParams: object = {}, fullUrl: boolean = false): string {
  if (!Object.keys(ROUTES).includes(slug)) {
    throw new Error("Invalid slug");
  }

  const [url, searchParams] = Object.entries(slugParams).reduce(
    ([result, resultParams]: [string, object], [key, value]: [string, any]) => {
      const replacement = `{${key}}`;
      const isSearchParam = result.indexOf(replacement) === -1;
      if (isSearchParam) {
        return [result, { ...resultParams, [key]: value }];
      }
      return [result.replace(replacement, value), resultParams];
    },
    // @ts-ignore
    [ROUTES[slug], {}]
  );

  return `${fullUrl ? DEFAULT_API_HOST : ""}${url}?${qs.stringify(searchParams)}`;
}
