import React, {FC} from "react";
import cn from "classnames";
import styles from "./Footer.module.scss";
import intuitLogo from "./intuit_logo.png";

interface IFooter {
  className?: string;
  isMobileCoverVisible?: boolean;
  fixWidth?: boolean;
}
const Footer: FC<IFooter> = ({className, isMobileCoverVisible, fixWidth}) => {
  const year = new Date().getFullYear();

  return (
    <div className={cn(styles.wrapper, isMobileCoverVisible && styles.mobileCoverVisible, !!className && className, fixWidth && styles.fixedWidth)}>
      <img height="27" src={intuitLogo} alt={'Intuit Logo'} />

      <p className={cn(styles.text, styles.rights)}>&copy; {year} Intuit Inc. All rights reserved.</p>
      <p className={styles.text}>Registered in England No. 2679414. Address 5th Floor, Cardinal Place, 80 Victoria Street, London, SW1E 5JL, England.</p>
      <p className={styles.text}>Intuit and QuickBooks are registered trademarks of Intuit Inc. Terms and conditions, features, support, pricing, and service options subject to change without notice.</p>
    </div>
  );
};

export default Footer;