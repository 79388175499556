import React, {FC} from "react";
import styles from "./LevelTile.module.scss";
import LevelStatus from "../LevelStatus/LevelStatus";
import { faStar as fasStar} from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar} from "@fortawesome/free-regular-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import cn from "classnames";
import {ILevel} from "../../types";
import Star from "../Star/Star";
import useUser from "../../hooks/getUser";
import LevelTileIcon from "../LevelTileIcon/LevelTileIcon";
import {STATUSES} from "../../pages/Program/Constants";
import {LEVELS} from "../../pages/Program/ProgramConfig";
import moment from "moment/moment";
import useLevel from "../../hooks/useLevel";

library.add(
  fasStar,
  farStar,
);

interface ILevelTileProps {
  level: ILevel;
  darkMode: boolean;
  onClick: (code: string) => void;
}

const LevelTile: FC<ILevelTileProps> = ({ level, darkMode, onClick }) => {
  const { getPrevLevelCode } = useLevel();
  const { getLevelStatus, getLevelConfig, getLevelCompletedActivities } = useUser();
  const config = getLevelConfig(level.code);
  const prevLevelCode = getPrevLevelCode(level.code);
  const prevLevelStatus = !prevLevelCode ? STATUSES.COMPLETED : getLevelStatus(prevLevelCode);
  // @ts-ignore
  const startDateOccurred = !!LEVELS[level.code] && (!LEVELS[level.code]?.startDate || moment().diff(LEVELS[level.code]?.startDate) >= 0)
  const status = getLevelStatus(level.code, prevLevelStatus, startDateOccurred);
  const completedActivities = getLevelCompletedActivities(level.code);

  return (
    <div className={cn(styles.wrapper, status === STATUSES.SOON && styles.inactive, darkMode && styles.dark)}
      onClick={() => onClick(level.code)}>
      <div className={styles.stars}>
        {config?.activities.map((_a, i) => (
          <Star key={`lt-star-${i}`} achieved={!!(_a.assetId || _a.url) && completedActivities.includes(_a.assetId || _a.url as string)} />
        ))}
      </div>

      <LevelTileIcon status={status} code={level.code} />

      <div className={styles.data}>
        <p>MODULE</p>
        <h1>{level.title}</h1>
        <LevelStatus status={status}/>
      </div>
    </div>
  )
};

export default LevelTile;