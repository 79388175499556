import {useFetch} from "use-http";
import getApiUrl from "../utils/getApiUrl";
import {useContext, useRef} from "react";
import currentLevelContext from "../context/currentLevelContext";

const useVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { currentLevelCode } = useContext(currentLevelContext);
  const { post: postVideoRequest, response: postVideoResponse } = useFetch<number>(
    getApiUrl(
      "partnermarketing_irp_video",
      {
        code: currentLevelCode
      },
      true
    ), {}
  );

  const onPlay = async (url: string, callback: (url: string) => void) => {
    await postVideoRequest({url});
    if (postVideoResponse.ok) {
      callback(url);
    }
  };

  return {
    onPlay,
    videoRef
  }
};

export default useVideo;
