import React, {FC} from "react";
import benefit from "./icon-benefit.png";
import styles from "./Benefit.module.scss";
import {LEVEL_CODES} from "../../pages/Program/ProgramConfig";
import training from "./icon-glasses.png";
import product from "./icon-product.png";
import marketing from "./icon-marketing.png";

interface IBenefitProps {
  title: string;
  text: string;
  code: string;
}

const Benefit: FC<IBenefitProps> = ({ title, text, code }) => {
  let icon = benefit;
  switch (code) {
    case LEVEL_CODES.TRAINING:
      icon = training;
      break;
    case LEVEL_CODES.PRODUCT:
      icon = product;
      break;
    default:
      icon = marketing;
      break;
  }
  return (
    <div className={styles.wrapper}>
      <img src={icon} alt={'Benefit icon'} />
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.text}>{text}</p>
    </div>
  );
}

export default Benefit;