import React, {FC} from "react";
import cn from "classnames";
import styles from "./LevelStatus.module.scss";
import {getStatusLabel} from "../../utils/helper";

interface ILevelStatusProps {
  status: string;
}

const LevelStatus: FC<ILevelStatusProps> = ({ status }) => (
  <span className={cn(styles.wrapper, styles[status])}>{getStatusLabel(status)}</span>
);

export default LevelStatus;