import React, {FC} from "react";
import styles from "./Program.module.scss";
import {useNavigate} from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import LeftBar from "./components/LeftBar/LeftBar";
import Main from "./components/Main/Main";
import useLevel from "../../hooks/useLevel";
import currentLevelContext from "../../context/currentLevelContext";
import cn from "classnames";
import useUser from "../../hooks/getUser";
import LoadingContainer from "../../components/LoadingContainer/LoadingContainer";

type ProgramType = {
  isMobileCoverVisible: boolean;
  fixWidth: boolean;
}

const Program: FC<ProgramType> = ({isMobileCoverVisible, fixWidth}) => {
  const navigate = useNavigate();
  const termsAccepted = window.localStorage.getItem('IRPTermsAccepted');
  React.useEffect(() => {
    if (!termsAccepted) {
      navigate('/');
    }
  }, [termsAccepted, navigate]);

  const {currentLevelCode, setCurrentLevel, currentLevelStatus, setCurrentLevelStatus, opened, open, close, initCall} = useLevel();
  const { loading, user } = useUser();

  return (
    <currentLevelContext.Provider
      value={{
        currentLevelCode,
        setCurrentLevel,
        currentLevelStatus,
        setCurrentLevelStatus,
        opened,
        open,
        close,
        initCall
    }}>
      <LoadingContainer loading={loading || !user}>
        <div className={cn(styles.wrapper, isMobileCoverVisible && styles.mobileCoverVisible, fixWidth && styles.fixedWidth)}>
          <LeftBar />
          <Main />
        </div>
        <Footer className={isMobileCoverVisible ? styles.mobileCoverVisible : ''}
          isMobileCoverVisible={isMobileCoverVisible}
        />
      </LoadingContainer>
    </currentLevelContext.Provider>
  );
};

export default Program;