import React, {FC} from "react";
import styles from "./Survey.module.scss";
import {Form} from "formik";
import ScaleField from "../ScaleField/ScaleField";
import useScaleField from "../ScaleField/useScaleField";
import cn from "classnames";
import TextareaSurveyField from "../TextareaField/TextareaSurveyField";

interface ISurvey {
  values: object;
  errors: object;
}

const Survey: FC<ISurvey> = ({values, errors}) => {
  const {
    getNumberOptions
  } = useScaleField('');

  return (
    <Form className={styles.form}>
      <ScaleField
        label={'1. How likely is it that you would recommend QuickBooks ProAdvisor ProSkills to a friend or colleague?*'}
        id={'recommend-proskills'}
        name={'recommendProSkills'}
        options={getNumberOptions(1, 11)}
        errors={errors}
      />
      <TextareaSurveyField
        label={'Please briefly tell us why you gave the above score? (optional)'}
        id={'recommended-proskills-comment'}
        name={'recommendedProSkillsComment'}
        errors={errors}
        maxChars={250}
      />
      <ScaleField
        label={'2. How likely is it that you would recommend  the QuickBooks ProAdvisor Programme  to a friend or colleague?*'}
        id={'recommend-programme'}
        name={'recommendProgramme'}
        options={getNumberOptions(1, 11)}
        errors={errors}
      />
      <TextareaSurveyField
        label={'Please briefly tell us why you gave the above score? (optional)'}
        id={'recommended-programme-comment'}
        name={'recommendedProgrammeComment'}
        errors={errors}
        maxChars={250}
      />
      <ScaleField
        label={'3. Did you learn anything new by completing the ProSkills modules?*'}
        id={'learn'}
        name={'learn'}
        options={[
          {label: 'Yes', value: 'yes'},
          {label: 'No', value: 'no'},
          {label: 'Unsure', value: 'unsure'},
        ]}
        errors={errors}
        isChoice={true}
      />
      <TextareaSurveyField
        label={'4. What did you find most valuable about the ProAdvisor ProSkills experience?*'}
        id={'most-valuable'}
        name={'mostValuable'}
        errors={errors}
        isSeparate={true}
        maxChars={250}
      />
      <label className={cn(Object.keys(errors).filter(
          errorFieldName => ['rateModule1', 'rateModule2', 'rateModule3'].includes(errorFieldName)
        ).length > 0 && styles.invalid)}
      >
        {'5. How would you rate your overall experience with the following:*'}
      </label>
      <ScaleField
        label={'Marketing resources provided in module 1'}
        id={'rate-module1'}
        name={'rateModule1'}
        options={getNumberOptions(1, 11)}
        errors={errors}
      />
      <ScaleField
        label={'Training resources provided in module 2'}
        id={'rate-module2'}
        name={'rateModule2'}
        options={getNumberOptions(1, 11)}
        errors={errors}
      />
      <ScaleField
        label={'Exploring product in module 3'}
        id={'rate-module3'}
        name={'rateModule3'}
        options={getNumberOptions(1, 11)}
        errors={errors}
      />
      <TextareaSurveyField
        label={'6. How can we improve the ProAdvisor ProSkills experience?*'}
        id={'how-improve'}
        name={'howImprove'}
        errors={errors}
        isSeparate={true}
        maxChars={250}
      />
      {!!Object.keys(errors).length && (
        <label className={cn(styles.general, styles.invalid)}>*Please fill in all required fields</label>
      )}
      <button type={'submit'}>{'Submit'}</button>
    </Form>
  );
};

export default Survey;
