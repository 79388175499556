import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import apiToken from "./utils/apiToken";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let search = window.location.search;
let searchParams = new URLSearchParams(search);
if (searchParams.has('isFromSso') && searchParams.has('userId') && searchParams.has('token')) {
  const userId = searchParams.get('userId');
  const token = searchParams.get('token');
  const isFromSso = searchParams.get('isFromSso');
  if (isFromSso === 'true' && token && userId) {
    apiToken.setApiAuth({token, userId, lang: 'en_GB'})
  }
}

root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
