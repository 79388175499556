import { useCallback, useState } from 'react';
import useModal from "./useModal";
import {useNavigate} from "react-router-dom";

interface IUseIntro {
  termsAccepted: boolean;
  modalOpened: boolean;
  openModal: () => void;
  closeModal: () => void;
  toggleTerms: () => void;
  getStarted: () => void;
};

const useIntro = (initialState = false): IUseIntro => {
  const navigate = useNavigate();
  const [termsAccepted, setTerms] = useState(initialState);
  const {modalOpened, openModal, closeModal} = useModal(false);
  const toggleTerms = useCallback(() => setTerms((prevFlag) => !prevFlag), [setTerms]);
  const getStarted = () => {
    const tcAcceptedEarlier = window.localStorage.getItem('IRPTermsWasAccepted');
    if (tcAcceptedEarlier) {
      window.localStorage.setItem('IRPTermsAccepted', '1');
      navigate('/program');
    }

    if (termsAccepted) {
      window.localStorage.setItem('IRPTermsAccepted', '1');
      window.localStorage.setItem('IRPTermsWasAccepted', '1');
      navigate('/program');
    } else {
      openModal();
    }
  }

  return {termsAccepted, toggleTerms, modalOpened, openModal, closeModal, getStarted};
};

export default useIntro;