let apiHost ='https://apiv2.partnermarketing.test';
let ssoHost ='https://sso.partnermarketing.test';
let feHost = 'http://intuit.partnermarketing.test:3000';
let pm2FEHost = 'http://science.partnermarketing.test';
switch (window.location.hostname) {
  case 'intuit.partnermarketing.test':
    apiHost = 'https://apiv2.partnermarketing.test';
    ssoHost = 'https://apiv2.partnermarketing.test';
    feHost = 'http://intuit.partnermarketing.test:3000';
    pm2FEHost = 'http://science.partnermarketing.test';
    break;
  case 'quickbooks-proadvisor-proskills.com':
    apiHost = 'https://apiv2.partnermarketing.com';
    ssoHost = 'https://sso.partnermarketing.com';
    feHost = 'https://quickbooks-proadvisor-proskills.com';
    pm2FEHost = 'https://quickbooks.partnermarketing.com';
    break;
  case 'www.quickbooks-proadvisor-proskills.com':
    apiHost = 'https://apiv2.partnermarketing.com';
    ssoHost = 'https://sso.partnermarketing.com';
    feHost = 'https://quickbooks-proadvisor-proskills.com';
    pm2FEHost = 'https://quickbooks.partnermarketing.com';
    break;
  case 'intuit-proskills.stage.partnermarketing.com':
    apiHost = 'https://apiv2.stage.partnermarketing.com';
    ssoHost = 'https://sso.stage.partnermarketing.com';
    feHost = 'https://intuit-proskills.stage.partnermarketing.com';
    pm2FEHost = 'https://quickbooks.stage.partnermarketing.com';
    break;
  case 'intuit-proskills.qa.partnermarketing.com':
    apiHost = 'https://apiv2.stage.partnermarketing.com';
    ssoHost = 'https://sso.stage.partnermarketing.com';
    feHost = 'https://intuit-proskills.qa.partnermarketing.com';
    pm2FEHost = 'https://quickbooks.qa.partnermarketing.com';
    break;
}

export const DEFAULT_API_HOST = apiHost;
export const DEFAULT_SSO_HOST = ssoHost;
export const DEFAULT_FE_HOST = feHost;
export const DEFAULT_PM2_FE_HOST = pm2FEHost;
