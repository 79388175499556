import React, {FC, useContext} from "react";
import styles from "./LeftBar.module.scss";
import useUser from "../../../../hooks/getUser";
import Status from "../../../../components/Status/Status";
import {LEVELS} from "../../ProgramConfig";
import LevelTile from "../../../../components/LevelTile/LevelTile";
import currentLevelContext from "../../../../context/currentLevelContext";
import RewardsTile from "../RewardsTile/RewardsTile";
import useLevel from "../../../../hooks/useLevel";
import {STATUSES} from "../../Constants";
import moment from "moment";

const LeftBar: FC = () => {
  const { getPrevLevelCode } = useLevel();
  const { getStatusPercent, isRewardsActive, getLevelStatus } = useUser();
  const {currentLevelCode, setCurrentLevel, close, initCall} = useContext(currentLevelContext);
  const percent = getStatusPercent();
  const levelClick = (code: string) => {
    const prevLevelCode = getPrevLevelCode(code);
    const prevLevelStatus = !prevLevelCode ? STATUSES.COMPLETED : getLevelStatus(prevLevelCode);
    // @ts-ignore
    const startDateOccurred = !!LEVELS[code] && (!LEVELS[code]?.startDate || moment().diff(LEVELS[code]?.startDate) >= 0)
    if ((code === 'rewards' && isRewardsActive())
      // @ts-ignore
      || (!!LEVELS[code] && LEVELS[code].active && prevLevelStatus === STATUSES.COMPLETED && startDateOccurred)) {
      setCurrentLevel(code);
    }
    close();
  };

  return (
    <div className={styles.leftBar}>
      <Status percent={percent} darkMode={initCall} />
      {Object.keys(LEVELS).map((key) => (
        <LevelTile key={`tile-${key}`}
          // @ts-ignore
          level={LEVELS[key]}
          darkMode={currentLevelCode === key && !initCall}
          onClick={levelClick}
        />
      ))}
      <RewardsTile onClick={levelClick} />
    </div>
  )
};

export default LeftBar;