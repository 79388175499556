import CryptoJS from "crypto-js";
import isEmpty from "lodash/isEmpty";
import { IAuthData, TSupportedLanguage } from "../types";

const KEY: string = "apiAuth";
const DEFAULT_LANGUAGE: TSupportedLanguage = "en_GB";

const isSupportedLanguage = (lang: string): lang is TSupportedLanguage => ["en_GB"].includes(lang);

class ApiToken {
  private apiAuth: IAuthData | undefined;

  private getItem(key: keyof IAuthData): string {
    if (isEmpty(this.apiAuth)) {
      try {
        this.apiAuth = JSON.parse(window.localStorage.getItem(KEY) || '');
      } catch (e) {
        // Item not exists.
      }
    }
    return this.apiAuth?.[key] || "";
  }
  getToken(): string {
    return this.getItem("token") || "";
  }
  getEncryptedToken(timestamp: string): string {
    return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(`timestamp=${timestamp}`, this.getToken()));
  }
  getUserId(): string {
    return this.getItem("userId");
  }
  getUserType() {
    return "user";
  }
  getUserLanguage(): TSupportedLanguage {
    const lang: string = this.getItem("lang");
    if (isSupportedLanguage(lang)) {
      return lang;
    }
    return DEFAULT_LANGUAGE;
  }
  getTimestamp(): string {
    return new Date().getTime().toString().substr(0, 10);
  }
  setApiAuth(data: IAuthData): void {
    window.localStorage.setItem(KEY, JSON.stringify(data));
    this.apiAuth = data;
  }
  clearApiAuth(): void {
    window.localStorage.removeItem(KEY);
  }
}

export default new ApiToken();
