import React, {FC} from "react";
import styles from "./RewardsTile.module.scss";
import {faFlagCheckered} from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import cn from "classnames";
import useUser from "../../../../hooks/getUser";
import icon from "./icon-rewards.png";

library.add(
  faFlagCheckered,
);

interface ILevelTileProps {
  onClick: (code: string) => void;
}

const RewardsTile: FC<ILevelTileProps> = ({ onClick }) => {
  const {isRewardsActive} = useUser();

  return (
    <div className={cn(styles.wrapper, !isRewardsActive() && styles.inactive)}
         onClick={() => onClick('rewards')}>

      <div className={styles.iconWrapper}>
        <div className={styles.icon}>
          <img src={icon} alt={`Rewards icon`} />
        </div>
      </div>

      <div className={styles.data}>
        <h1>View rewards</h1>
      </div>
    </div>
  )
};

export default RewardsTile;