import React, {FC} from "react";
import styles from "./Intro.module.scss";
import Logo from "../../components/Logo/Logo";
import Benefit from "../../components/Benefit/Benefit";
import Modal from "../../components/Modal/Modal";
import useIntro from "../../hooks/useIntro";
import Footer from "../../components/Footer/Footer";
import useModal from "../../hooks/useModal";
import TC from "../../components/TC/TC";
import cn from "classnames";
import {LEVEL_CODES} from "../Program/ProgramConfig";

type IntroType = {
  isMobileCoverVisible: boolean;
  fixWidth: boolean;
}


const Intro: FC<IntroType> = ({isMobileCoverVisible, fixWidth}) => {
  const {termsAccepted, toggleTerms, modalOpened, closeModal, getStarted} = useIntro(false);
  const {modalOpened: tcModalOpened, openModal: openTcModal, closeModal: closeTcModal} = useModal(false);

  return (
    <>
      <Modal
        isOpened={tcModalOpened}
        title={'OFFICIAL RULES OF QUICKBOOKS UK PROADVISOR PROSKILLS CAMPAIGN COMPETITION'}
        cancel={() => closeTcModal()}
        wrapperClassName={styles.tcModal}
        className={styles.modalText}
      >
        <TC />
      </Modal>

      <Modal
        isOpened={modalOpened}
        title={'Terms & Conditions'}
        text={'Please agree to the Terms & Conditions before starting ProAdvisor ProSkills activities.'}
        isConfirmButtonActive={termsAccepted}
        confirmText={'Get started now'}
        confirm={getStarted}
        cancel={() => closeModal()}
        className={styles.modalTerms}
      >
        <p>
          <input type="checkbox" id="terms" name="terms" checked={termsAccepted} onChange={toggleTerms} />
          <label htmlFor="terms">I agree to the <span className={styles.link} onClick={(e) => {
            e.preventDefault();
            openTcModal();
          }}>Terms & Conditions</span></label>
        </p>
      </Modal>

      <div className={cn(styles.welcomeBanner, isMobileCoverVisible && styles.mobileCoverVisible, fixWidth && styles.fixedWidth)}>
        <Logo type={'white'} />
        <h1>HELLO PROADVISOR</h1>
        <p>Welcome to ProAdvisor ProSkills. Boost your practice by enhancing your knowledge and skills. It’s as easy as Ready. Set. Pro.</p>
        <p className={styles.terms}>
          <input type="checkbox" id="terms" name="terms" checked={termsAccepted} onChange={toggleTerms} />
          <label htmlFor="terms">I agree to the <span className={styles.link} onClick={(e) => {
            e.preventDefault();
            openTcModal();
          }}>Terms & Conditions</span></label>
        </p>
        <button className={styles.button} onClick={getStarted}>Get started now</button>
      </div>

      <div className={cn(styles.benefits, isMobileCoverVisible && styles.mobileCoverVisible, fixWidth && styles.fixedWidth)}>
        <h1>How it works</h1>
        <p>Earn rewards for doing what you love. ProAdvisor ProSkills is designed to help you grow your QuickBooks knowledge, build your brand to attract and retain new clients, and show you the latest enhancements in product.</p>
        <p>The programme has 3 modules: marketing, training and product. Simply complete three activities in each module to earn an accreditation badge to promote your success on your professional social media platforms. You can also be entered into a prize draw for each module you complete.</p>
        <div className={styles.benefitList}>
          <Benefit code={LEVEL_CODES.MARKETING} title={"Marketing"} text={"Access new exclusive marketing resources in My Marketing Hub to help you build your brand and grow your practice."} />
          <Benefit code={LEVEL_CODES.TRAINING} title={"Training"} text={"Complete new training modules expanding your expertise, to better support your practice and your clients."} />
          <Benefit code={LEVEL_CODES.PRODUCT} title={"Product"} text={"Check out new features in QuickBooks Online Accountant that can help you and your clients thrive."} />
        </div>
      </div>

      <Footer className={isMobileCoverVisible ? styles.mobileCoverVisible : ''}
        fixWidth={fixWidth}
        isMobileCoverVisible={isMobileCoverVisible} />
    </>
  );
};

export default Intro;