import React, {FC, useContext} from "react";
import marketing from './icon-marketing.png';
import training from './icon-glasses.png';
import product from './icon-product.png';
import { library } from '@fortawesome/fontawesome-svg-core'
import styles from "./LevelTileIcon.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faCheck} from "@fortawesome/free-solid-svg-icons";
import currentLevelContext from "../../context/currentLevelContext";
import {STATUSES} from "../../pages/Program/Constants";
import {LEVEL_CODES} from "../../pages/Program/ProgramConfig";
import cn from "classnames";

library.add(faMinus, faCheck);

interface ILevelTileIconProps {
  status: string;
  code: string;
}

const LevelTileIcon: FC<ILevelTileIconProps> = ({ status, code }) => {
  const {currentLevelCode, initCall} = useContext(currentLevelContext);
  const isCurrent = currentLevelCode === code;
  let icon = product;
  switch (code) {
    case LEVEL_CODES.TRAINING:
      icon = training;
      break;
    case LEVEL_CODES.PRODUCT:
      icon = product;
      break;
    default:
      icon = marketing;
      break;
  }
  return (
    <div className={styles.wrapper}>
      {!initCall && (status === STATUSES.IN_PROGRESS || (status === STATUSES.PENDING  && isCurrent)) && (
        <div className={styles.icon}><FontAwesomeIcon icon={faMinus} /></div>
      )}
      {!initCall && status === STATUSES.COMPLETED && (
        <div className={cn(styles.icon, !isCurrent && styles.notActive)}><FontAwesomeIcon icon={faCheck} /></div>
      )}
      {(initCall || (![STATUSES.IN_PROGRESS, STATUSES.COMPLETED].includes(status) && !isCurrent)) && (
        <img src={icon} alt={`${code} level icon`} />
      )}
    </div>
  )
};

export default LevelTileIcon;