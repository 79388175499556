import React, {FC} from "react";
import cn from "classnames";
import styles from "./ProgressLine.module.scss";

interface IProgressLineProps {
    percent: number;
}

const ProgressLine: FC<IProgressLineProps> = ({percent}) => {
  const style = {
    width: percent + '%',
    background: "#0fd46c"
  };

  if (!percent) {
    style.background = "transparent";
  }

    return (
        <div className={cn(styles.wrapper)}>
            <span className={styles.bar}>
                <span className={styles.progress} style={style}></span>
            </span>
        </div>
    );
};

export default ProgressLine;