import React, {FC, useState} from "react";
import styles from "./TextareaSurveyField.module.scss";
import {useField} from "formik";
import cn from "classnames";

interface ITextareaSurveyField {
  label: string;
  id: string;
  name: string;
  errors: object;
  isSeparate?: boolean;
  maxChars: number;
}

const getCharsLeft = (value: string, maxChars: number) => (maxChars ? maxChars - String(value).length : 9999);

const TextareaSurveyField: FC<ITextareaSurveyField> = ({id, name, label, errors, isSeparate, maxChars, ...props}) => {
  const [, , { setValue }] = useField(name);
  const [textValue, setTextValue] = useState('');

  return (
    <div className={cn(styles.wrapper, Object.keys(errors).includes(name) && styles.invalid, isSeparate && styles.separate)}>
      <label htmlFor={id} key={id} className={cn(isSeparate && styles.bold)}>
        {label}
      </label>
      <textarea
        id={id}
        name={name}
        onChange={e => {
          const charsLeft = getCharsLeft(e.target.value, maxChars);
          if (charsLeft >= 0) {
            setValue(e.target.value);
            setTextValue(e.target.value);
          }
          if (charsLeft < 0) {
            setValue(e.target.value.substr(0, maxChars));
            setTextValue(e.target.value.substr(0, maxChars));
          }
        }}
        value={textValue}
      />
      {!!maxChars && (
        <div className={styles.charCounter}>
          <div>{textValue.length}/{maxChars}</div>
        </div>
      )}
    </div>
  );
};

export default TextareaSurveyField;