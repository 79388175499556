import React, {FC, useContext} from "react";
import styles from "./BadgeBox.module.scss";
import badgeMarketing from './badge-marketing.png';
import badgeTraining from './badge-training.png';
import badgeProduct from './badge-product.png';
import Button from "../../../../components/Button/Button";
import cn from "classnames";
import currentLevelContext from "../../../../context/currentLevelContext";
import useUser from "../../../../hooks/getUser";
import SocialsShare from "../SocialsShare/SocialsShare";

interface IBadgeBoxProps {
  icon: string;
  moduleCode: string;
  levelTitle: string;
  title: string;
  description: string;
  action: string;
  url: string;
  isDisabled?: boolean;
  className?: string;
}

const BadgeBox: FC<IBadgeBoxProps> = ({levelTitle, icon, moduleCode, title, description, action, url, isDisabled, className}) => {
  const { currentLevelCode } = useContext(currentLevelContext);
  const { getLevelData } = useUser();
  const level = getLevelData(currentLevelCode);
  const isInactive = !!url && !!level && level.completedActivities.includes(url);

  let iconSrc = badgeMarketing;
  switch (icon) {
    case "badge-marketing":
      iconSrc = badgeMarketing;
      break;
    case "badge-training":
      iconSrc = badgeTraining;
      break;
    case "badge-product":
      iconSrc = badgeProduct;
      break;
  };

  return (
    <div className={cn(styles.wrapper, !!className && className)}>
      <div className={styles.texts}>
        <div className={styles.top}>
          <div>
            <img src={iconSrc} alt={'activity'} />
          </div>
          <div className={styles.title}>
            <span>{levelTitle}</span>
            <h1>{title}</h1>
          </div>
        </div>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.bottom}>
        <Button
          className={cn(styles.button, isInactive && styles.inactive)}
          title={action}
          // @ts-ignore
          onClick={() => {
            if (isInactive || isDisabled) {
              return;
            }
            window.open(
              url,
              '_blank'
            );
          }}
        />
        <SocialsShare
          code={moduleCode}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  )
};

export default BadgeBox;