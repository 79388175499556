import React, {FC} from "react";
import white from "./logo-white.png";
import black from "./logo-black.png";
import styles from "./Logo.module.scss";
import {DEFAULT_FE_HOST} from "../../constants";

type TLogoType = "white" | "black";

interface ILogoProps {
  type?: TLogoType;
}

const Logo: FC<ILogoProps> = ({ type }) => (
  <img
    onClick={() => {
      window.localStorage.setItem('IRPTermsAccepted', '');
      window.location.href = DEFAULT_FE_HOST;
    }}
    src={type === 'white' ? white : black}
    className={styles.logo} alt={'Intuit logo'}
  />
);

export default Logo;