import {range} from "lodash";
import {IScaleOption} from "../../types";
import {useCallback, useState} from "react";

interface IUseScaleField {
  onClick: (_v: string) => void,
  getNumberOptions: (min: number, max: number) => IScaleOption[],
  value: string | undefined
}


const useScaleField = (initialValue: string): IUseScaleField => {
  const [value, setValue] = useState<string | undefined>(initialValue);

  const onClick = useCallback((v:string) => {
    setValue(v);
  }, [setValue]);

  const getNumberOptions = (min: number, max: number):IScaleOption[] => {
    return range(min, max).map(value => {
      return {
        label: '' + value,
        value: '' + value
      };
    })
  };

  return {
    onClick,
    getNumberOptions,
    value
  }
};

export default useScaleField;
