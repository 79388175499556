import React, {FC} from 'react';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import styles from './Icon.module.scss';
import * as svg from './svg';

interface IIcon {
  kind: string;
  dark?: string;
  className: string;
}

/**
 * Render an icon. Kind is mentioned under the actual icon.
 */
const Icon: FC<IIcon> = ({ kind, dark, className }) => {
  // @ts-ignore
  const SvgIcon = svg[camelCase(kind)];
  return (
    <span
      className={cn(
        styles.icon,
      styles[`icon-${kind}`],
      SvgIcon && styles.svgIcon,
      dark && styles.dark,
        className
    )}
    >
    {!!SvgIcon && <SvgIcon />}
  </span>
);
};

export default Icon;
