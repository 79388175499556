import React, {FC, useContext} from "react";
import styles from "./RewardsBox.module.scss";
import marketingBadge from './marketing-badge.png';
import trainingBadge from './training-badge.png';
import productBadge from './product-badge.png';
import prizeDraw from './prize-draw.png';
import prizeDrawUnknown from './prize-draw-unknown.png';
import prizeDrawWin from './prize-draw-win.png';
import Button from "../../../../components/Button/Button";
import cn from "classnames";
import currentLevelContext from "../../../../context/currentLevelContext";
import {LEVEL_CODES, REWARDS} from "../../ProgramConfig";
import useUser from "../../../../hooks/getUser";
import {OPT_IN_STATUS, STATUSES, WIN_STATUS} from "../../Constants";
import {wait} from "../../../../utils/helper";
import {useFetch} from "use-http";
import getApiUrl from "../../../../utils/getApiUrl";
import Modal from "../../../../components/Modal/Modal";
import useModal from "../../../../hooks/useModal";
import moment from "moment";
import TC from "../../../../components/TC/TC";
import rosette from "../Level/icon-rosette.png";
import useLevel from "../../../../hooks/useLevel";

const RewardsBox: FC = () => {
  const {currentLevelCode} = useContext(currentLevelContext);
  const {getLevelDrawOptInStatus, hasWonLevelPrize, getUser, getLevelConfig, getLevelStatus} = useUser();
  const {modalOpened, openModal, closeModal} = useModal(false);
  const {modalOpened: tcModalOpened, openModal: openTcModal, closeModal: closeTcModal} = useModal(false);
  const inPrizeDraw = getLevelDrawOptInStatus(currentLevelCode);
  const hasWon = hasWonLevelPrize(currentLevelCode);
  const config = getLevelConfig(currentLevelCode);
  const levelStatus = getLevelStatus(currentLevelCode);
  const {optedIn, optIn} = useLevel();
  const isPrizeDrawBoxActive = inPrizeDraw !== OPT_IN_STATUS.UNKNOWN || levelStatus === STATUSES.COMPLETED;
  const optInDateActive = !config?.lastOptInDate || new Date() < new Date(config?.lastOptInDate);
  const checkDateActive = !config?.checkDate || new Date() < new Date(config?.checkDate);
  const showPrizeBox = false;

  let badge = marketingBadge;
  switch (currentLevelCode) {
    case LEVEL_CODES.TRAINING:
      badge = trainingBadge;
      break;
    case LEVEL_CODES.PRODUCT:
      badge = productBadge;
      break;
  }
  let prizeIcon = prizeDraw;
  if (optInDateActive && inPrizeDraw === OPT_IN_STATUS.YES && hasWon === WIN_STATUS.UNKNOWN) {
    prizeIcon = prizeDrawUnknown;
  }
  if (inPrizeDraw === OPT_IN_STATUS.YES && hasWon === WIN_STATUS.YES) {
    prizeIcon = prizeDrawWin;
  }

  const { post: optInApiCallTrue, response: optInTrueApiResponse } = useFetch<number>(
    getApiUrl(
      "partnermarketing_irp_opt_in_prize_draw",
      {
        code: currentLevelCode,
        status: true
      },
      true
    ), {}
  );

  const { post: optInApiCallFalse, response: optInFalseApiResponse } = useFetch<number>(
    getApiUrl(
      "partnermarketing_irp_opt_in_prize_draw",
      {
        code: currentLevelCode,
        status: false
      },
      true
    ), {}
  );

  return (
    <div className={styles.wrapper}>
      <Modal
        isOpened={tcModalOpened}
        title={'OFFICIAL RULES OF QUICKBOOKS UK PROADVISOR PROSKILLS CAMPAIGN COMPETITION'}
        cancel={() => closeTcModal()}
        wrapperClassName={styles.tcModal}
        className={styles.modalText}
      >
        <TC />
      </Modal>

      <Modal
        isOpened={modalOpened}
        title={'Congratulations!'}
        text={inPrizeDraw === OPT_IN_STATUS.YES ? 'You’ve been entered into the prize draw' : 'You’ve completed all of the activities in this module.'}
        confirmText={inPrizeDraw === OPT_IN_STATUS.YES ? `Go back to ${config?.title}` : `Enter me into the draw`}
        isConfirmButtonActive={optedIn || inPrizeDraw === OPT_IN_STATUS.YES}
        showCancelButton={inPrizeDraw !== OPT_IN_STATUS.YES}
        cancelText={`Go back to ${config?.title}`}
        confirm={async () => {
          if (inPrizeDraw === OPT_IN_STATUS.YES) {
            closeModal();
            return;
          }

          if (!optedIn) {
            return;
          }

          await optInApiCallTrue();
          if (optInTrueApiResponse.ok) {
            closeModal();
            await wait(1000).then(() => {
              getUser();
              openModal();
            });
          } else {
            alert('An api error occurred. Please try later.');
            closeModal();
          }
        }}
        cancel={async () => {
          if (inPrizeDraw === OPT_IN_STATUS.YES) {
            closeModal();
            return;
          }

          await optInApiCallFalse();
          if (optInFalseApiResponse.ok) {
            closeModal();
            await wait(1000).then(() => {
              getUser();
            });

          } else {
            alert('An api error occurred. Please try later.');
            closeModal();
          }
        }}
        className={styles.modalWrapper}
      >
        {inPrizeDraw !== OPT_IN_STATUS.YES && (
          <>
            <div className={styles.modal}>
              <div>
                <img src={badge} alt={'Badge icon'} /><p>{`Download your ProSkills badge from the ${config?.title} page`}</p>
              </div>
              <div>
                <img src={rosette} alt={'Prize draw icon'} /><p>Enter into the prize draw. Keep an eye on your inbox to see if you’ve won a prize…</p>
              </div>
            </div>
            <p className={styles.terms}>
              <input type="checkbox" id="terms" name="terms" checked={optedIn} onChange={() => optIn()} />
              <label htmlFor="terms">I agree to the <span className={styles.link} onClick={(e) => {
                e.preventDefault();
                openTcModal();
              }}>Terms & Conditions</span></label>
            </p>
          </>
        )}
      </Modal>

      <h1>Rewards</h1>
      <p>Complete all above activities to unlock these rewards…</p>
      <div className={cn(styles.boxes, !isPrizeDrawBoxActive && styles.inactive)}>
        <div className={styles.badge}>
          <div className={styles.image}>
            <img src={badge} alt={'Badge icon'} />
          </div>
          <div className={styles.texts}>
            <p>Download my badge now</p>
            <Button
              className={cn(styles.button)}
              title={'Download my badge'}
              onClick={() => {
                if (!isPrizeDrawBoxActive) {
                  return;
                }
                window.open(
                  // @ts-ignore
                  REWARDS[currentLevelCode].url,
                  '_blank'
                );
              }}
            />
          </div>
        </div>
        {showPrizeBox && (<div className={styles.prize}>
          <div className={styles.image}>
            <img src={prizeIcon} alt={'Prize icon'} />
          </div>
          <div className={styles.text}>
            {!isPrizeDrawBoxActive && 'Enter into prize draw'}
            {levelStatus === STATUSES.COMPLETED && (inPrizeDraw === OPT_IN_STATUS.UNKNOWN || inPrizeDraw === OPT_IN_STATUS.NO) && (
              <>
              {optInDateActive && (
                <div className={styles.texts}>
                  <p>You haven’t entered the prize draw.</p>
                  {config && config?.lastOptInDate && (
                    <p>{`You can still enter by ${moment(config?.lastOptInDate).format('Do MMMM')}.`}</p>
                  )}

                  <Button
                    className={cn(styles.button, !optInDateActive && styles.inactive)}
                    title={'Enter here'}
                    onClick={async () => {
                      if (!optInDateActive) {
                        return;
                      }
                      openModal();
                    }}
                  />
                </div>
              )}
              {!optInDateActive && (
                <div className={styles.texts}>
                  <p>{`You're too late to enter the ${config.title} prize draw`}</p>
                </div>
              )}
              </>
            )}
            {inPrizeDraw === OPT_IN_STATUS.YES && (
              <>
              {checkDateActive && hasWon === WIN_STATUS.UNKNOWN && (
                <>
                  <p>You have been entered into the prize draw.</p>
                  {config && config?.checkDate && (
                    <p>{`Check your email after ${moment(config?.checkDate).format('Do MMMM')} to see if you’ve won a prize…`}</p>
                  )}
                </>
              )}
              {hasWon === WIN_STATUS.YES && (
                <>
                  <p>You’ve been entered into a prize draw and you’ve won a prize. You’ll be contacted by one of the team.</p>
                </>
              )}
              {(hasWon === WIN_STATUS.NO || (!checkDateActive && hasWon === WIN_STATUS.UNKNOWN)) && (
                <>
                  <p>Sorry, you haven’t been successful this time. You can try again in the next module.</p>
                </>
              )}
              </>
            )}
          </div>
        </div>
        )}
      </div>
    </div>
  )
};

export default RewardsBox;