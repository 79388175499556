import React, {FC} from "react";
import styles from "./Rewards.module.scss";
import {LEVELS, PRIZE_DRAWS, REWARDS} from "../../ProgramConfig";
import {STATUSES} from "../../Constants";
import useUser from "../../../../hooks/getUser";
import PrizeDrawItemBox from "../PrizeDrawItemBox/PrizeDrawItemBox";
import BadgeBox from "../BadgeBox/BadgeBox";
import MonthPrizeDrawItemBox from "../MonthPrizeDrawItemBox/MonthPrizeDrawItemBox";

const Rewards: FC  = () => {
  const {getLevelStatus} = useUser();
  const showLevelPrizeDraw = false;

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <h1 className={styles.title}>Rewards</h1>
        <p className={styles.subtitle}>Congratulations on completing ProAdvisor modules.</p>
      </div>

      <div className={styles.actions}>
        <h2>Showcase your ProSkills</h2>
        <p className={styles.description}>Display your ProSkills badges on social media to showcase your expertise to new and potential clients.</p>
        <div className={styles.activities}>
          {Object.values(REWARDS).map((activity, index) => (
            <BadgeBox
              key={`reward-${index}`}
              levelTitle={`Module ${activity.level}`}
              moduleCode={activity.code}
              icon={`badge-${activity.code}`}
              title={activity.title}
              description={activity.description}
              action={activity.action}
              url={activity?.url}
              isDisabled={getLevelStatus(activity.code) !== STATUSES.COMPLETED}
              className={getLevelStatus(activity.code) !== STATUSES.COMPLETED ? styles.inactiveBadgeBox : ''}
            />
          ))}
        </div>
      </div>

      <div className={styles.actions}>
        <h2>Prize Draw</h2>
        <p className={styles.description}>Complete the activities to be eligible to enter into the prize draws. We’ll be in touch though email or phone if you’ve won.</p>
        {showLevelPrizeDraw && (
        <div className={styles.activities}>
          {Object.values(LEVELS).map((level, ind) => (
            <PrizeDrawItemBox
              key={`reward-opt-in-${ind}`}
              code={level.code}
              title={level.title}
            />
          ))}
        </div>
        )}
        <div className={styles.activities}>
          {Object.values(PRIZE_DRAWS).map((draw, ind) => (
            <MonthPrizeDrawItemBox
              key={`prize-draw-${ind}`}
              code={draw.code}
              title={draw.title}
            />
          ))}
        </div>
      </div>
    </div>
  )
};

export default Rewards;