import React from 'react';
import './App.css';
import Intro from "./pages/Intro/Intro";
import {Route, Routes, useNavigate} from "react-router-dom";
import Program from "./pages/Program/Program";
import {IncomingOptions, Provider} from "use-http";
import {DEFAULT_API_HOST, DEFAULT_FE_HOST, DEFAULT_SSO_HOST} from "./constants";
import apiToken from "./utils/apiToken";
import MobileDeviceCover from "./components/MobileDeviceCover/MobileDeviceCover";
import useMobileCover from "./hooks/useMobileCover";

const options: IncomingOptions = {
  interceptors: {
    request: async ({ options, url, route, path }) => {

      if (apiToken.getUserId()) {
        const timestamp = apiToken.getTimestamp();
        options.headers = {...options.headers,
          'x-pm-user': apiToken.getUserId(),
          'x-pm-usertype': apiToken.getUserType(),
          'x-pm-timestamp': timestamp,
          'x-pm-token': apiToken.getEncryptedToken(timestamp)
        };
        // options.headers["x-pm-user"] = apiToken.getUserId();
        // options.headers["x-pm-usertype"] = apiToken.getUserType();

        // options.headers["x-pm-timestamp"] = timestamp;
        // options.headers["x-pm-token"] = apiToken.getEncryptedToken(timestamp);
      }
      return options;
    },
    response: async ({ response }) => {
      if (response.status === 401) {
         window.location.href = `${DEFAULT_SSO_HOST}/non-saml/quickbooks/login?callback=${DEFAULT_FE_HOST}`;
      }
      return response;
    },
  },
};

function App() {
  const navigate = useNavigate();
  const termsAccepted = window.localStorage.getItem('IRPTermsAccepted');
  const {opened: isMobileCoverVisible, close: hideMobileCover, fixedWidth, fixWidth} = useMobileCover(true);

  React.useEffect(() => {
    if (termsAccepted) {
      navigate('/program');
    }
  }, [termsAccepted, navigate]);

  return (
    <div className="App">
      <MobileDeviceCover isOpened={isMobileCoverVisible} close={hideMobileCover} fixWidth={fixWidth} />
      <Provider url={DEFAULT_API_HOST} options={options}>
        <Routes>
          <Route path="/" element={ <Intro isMobileCoverVisible={isMobileCoverVisible} fixWidth={fixedWidth} /> } />
          <Route path="program" element={ <Program isMobileCoverVisible={isMobileCoverVisible} fixWidth={fixedWidth} /> } />
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
