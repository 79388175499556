import { useCallback, useState } from 'react';
import {LEVEL_CODES} from "../pages/Program/ProgramConfig";
import {STATUSES} from "../pages/Program/Constants";

interface IUseLevel {
  // userName: string;
  currentLevelCode: string;
  setCurrentLevel: (code:string) => void;
  currentLevelStatus: string;
  setCurrentLevelStatus: (status:string) => void;
  initCall: boolean;
  opened: boolean;
  open: () => void;
  close: () => void;
  optedIn: boolean;
  optIn: () => void;
  getPrevLevelCode: (code:string) => string;
}

const useLevel = (initialCode = LEVEL_CODES.MARKETING, initialStatus = STATUSES.PENDING, firstInitCall = true): IUseLevel => {
  const [initCall, setInitCall] = useState(firstInitCall);
  const [currentLevelCode, setCurrent] = useState(initialCode);
  const setCurrentLevel = useCallback((code:string) => {
    setCurrent(code);
    setInitCall(false);
  }, [setCurrent, setInitCall]);

  const [currentLevelStatus, setStatus] = useState(initialStatus);
  const setCurrentLevelStatus = useCallback((status:string) => setStatus(status), [setStatus]);

  const [opened, setOpened] = useState(false);
  const open = useCallback(() => {
    setOpened(true);
    setInitCall(false);
  }, [setOpened, setInitCall]);
  const close = useCallback(() => setOpened(false), [setOpened]);

  const [optedIn, setOptedIn] = useState(false);
  const optIn = useCallback(() => {
    setOptedIn((prevFlag) => !prevFlag);
  }, [setOptedIn]);

  const getPrevLevelCode = (currentCode: string): string => {
    let prevCode;
    switch (currentCode) {
      case LEVEL_CODES.TRAINING:
        prevCode = LEVEL_CODES.MARKETING;
        break;
      case LEVEL_CODES.PRODUCT:
        prevCode = LEVEL_CODES.TRAINING;
        break;
      default:
        prevCode = '';
    }

    return prevCode;
  };

  return {
    currentLevelCode,
    setCurrentLevel,
    currentLevelStatus,
    setCurrentLevelStatus,
    opened,
    open,
    close,
    optedIn,
    optIn,
    getPrevLevelCode,
    initCall
  };
};

export default useLevel;