import React, {FC} from "react";
import styles from "./Button.module.scss";
import cn from "classnames";

interface IButtonProps {
  title: string;
  onClick: () => void;

  className?: string;
}

const Button: FC<IButtonProps> = ({ title, onClick, className }) => (
  <button className={cn(styles.button, className && className)} onClick={onClick}>
    {title}
  </button>
);

export default Button;