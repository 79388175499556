import React, {FC} from "react";
import { faStar as fasStar} from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar} from "@fortawesome/free-regular-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

library.add(
  fasStar,
  farStar,
);

interface IStarProps {
  achieved?: boolean;
}

const Star: FC<IStarProps> = ({ achieved }) => {
  return (
    achieved ? <FontAwesomeIcon icon={fasStar}/> : <FontAwesomeIcon icon={farStar}/>
  );
};

export default Star;