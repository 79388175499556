import React, {FC, useState} from "react";
import styles from "./SocialsShare.module.scss";
import iconShare from "./icon-share.png";
import {DEFAULT_PM2_FE_HOST} from "../../../../constants";
import cn from "classnames";

interface ISocialsShareProps {
  code: string;
  isDisabled?: boolean;
}

const SocialsShare: FC<ISocialsShareProps> = ({code, isDisabled}) => {
  const [socialsVisible, setSocialsVisible] = useState(false);

  return (
    <>
      <span className={styles.link}
        onMouseEnter={() => {
          if (isDisabled) {
            return;
          }
          setSocialsVisible(true);
        }}
        onMouseLeave={() => {
          setSocialsVisible(false);
        }}
      >
        <img src={iconShare} alt={'share'}
          onClick={() => {
            if (isDisabled) {
              return;
            }

            window.open(
              `${DEFAULT_PM2_FE_HOST}/social_post/from_code/irp_${code.toLowerCase()}`,
              '_blank'
            )
          }}
        />
        <div className={cn(styles.bubble, socialsVisible && styles.visible)}
         onMouseEnter={() => {
           if (isDisabled) {
             return;
           }
           setSocialsVisible(true);
         }}
         onMouseLeave={() => {
           if (isDisabled) {
             return;
           }
           setSocialsVisible(false);
         }}
        >
        <span
          onClick={() => {
            if (isDisabled) {
              return;
            }

            window.open(
              `${DEFAULT_PM2_FE_HOST}/social_post/from_code/irp_${code.toLowerCase()}`,
              '_blank'
            )
          }}
        >Share via My Marketing Hub</span>
      </div>
      </span>

    </>
  );
};

export default SocialsShare;