import { createContext } from "react";
import {LEVEL_CODES} from "../pages/Program/ProgramConfig";
import {STATUSES} from "../pages/Program/Constants";

interface ICurrentLevelContext {
  currentLevelCode: string;
  setCurrentLevel: (code:string) => void;
  currentLevelStatus: string;
  setCurrentLevelStatus: (status:string) => void;
  opened: boolean;
  initCall: boolean;
  open: () => void;
  close: () => void;
};

const currentLevelContext = createContext<ICurrentLevelContext>({
  currentLevelCode: LEVEL_CODES.MARKETING,
  setCurrentLevel: () => {},
  currentLevelStatus: STATUSES.PENDING,
  setCurrentLevelStatus: () => {},
  opened: false,
  initCall: true,
  open: () => {},
  close: () => {},
});
export default currentLevelContext;