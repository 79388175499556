import React, {FC} from "react";
import styles from "./ScaleField.module.scss";
import {IScaleOption} from "../../types";
import useScaleField from "./useScaleField";
import {useField} from "formik";
import cn from "classnames";

interface IScaleField {
  label: string;
  id: string;
  name: string;
  options: IScaleOption[];
  errors: object;
  isChoice?: boolean;
}

const ScaleField: FC<IScaleField> = ({id, name, label, options, errors, isChoice}) => {
  const {
    onClick,
    value
  } = useScaleField('');

  const [, , { setValue }] = useField(name);

  return (
    <div className={cn(styles.wrapper, Object.keys(errors).includes(name) && styles.invalid)}>
      <label htmlFor={id} key={id}>{label}</label>
      <input
        id={id}
        name={name}
        type={'hidden'}
        value={value}
      />
      <div className={cn(styles.scale, isChoice && styles.choice)}>
        {options.map((option: IScaleOption, index) => {
          return (
            <div
              className={styles.optionWrapper}
              key={`scale-${id}-option-${index}`}>
              <div
                className={cn(styles.circle, value === option.value && styles.active)}
                onClick={() => {
                  onClick(option.value);
                  setValue(option.value);
                }}
              ><>
                {value === option.value && (
                  <div className={styles.innerCircle}></div>
                )}
                {!isChoice && option.label}
              </></div>
              {isChoice && (
                <span key={`choice-${id}-option-${index}`} className={styles.choiceLabel}>{option.label}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScaleField;