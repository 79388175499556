import React, {FC} from "react";
import cn from "classnames";
import styles from "./Modal.module.scss";

interface IModalProps {
  children?: React.ReactNode
  isOpened: boolean;
  title: string;
  text?: string;
  confirmText?: string;
  cancelText?: string;
  confirm?: () => void;
  cancel: () => void;
  isConfirmButtonActive?: boolean;
  showCancelButton?: boolean;
  className?: string;
  wrapperClassName?: string;
}

const Modal: FC<IModalProps> = ({ children, isOpened, title, text, confirmText, cancelText, confirm, cancel, isConfirmButtonActive, showCancelButton, className, wrapperClassName }) => (
  <div className={cn(styles.wrapper, isOpened ? styles.open : styles.close, !!wrapperClassName && wrapperClassName)}>
    <div className={cn(styles.content, className)}>
      <span className={styles.close} onClick={cancel}></span>
      <h1 className={styles.title}>{title}</h1>
      {!!text && (
        <p className={styles.text}>{text}</p>
      )}
      {children}
      <span className={styles.actions}>
        {showCancelButton && (
          <button className={cn(styles.button, styles.black)} onClick={cancel}>{cancelText ? cancelText : 'Cancel'}</button>
        )}
        {confirm && (
          <button className={cn(styles.button, isConfirmButtonActive ? '' : styles.disabled)} onClick={confirm}>{confirmText ? confirmText : 'Confirm'}</button>
        )}
      </span>
    </div>
  </div>
);

export default Modal;