export const LEVEL_CODES = {
  MARKETING: 'marketing',
  TRAINING: 'training',
  PRODUCT: 'product',
};

export const REWARDS = {
  marketing: {
    code: LEVEL_CODES.MARKETING,
    level: 'I',
    title: 'ProSkills Marketing',
    description: 'Download your ProSkills Marketing badge.',
    action: 'Download badge',
    url: 'https://s3.eu-west-1.amazonaws.com/files.partnermarketing.com/irp/Module-1-MARKETING-65dceceec9584822960260b43836991a.zip'
  },
  training: {
    code: LEVEL_CODES.TRAINING,
    level: 'II',
    title: 'ProSkills Training',
    description: 'Download your ProSkills Training badge.',
    action: 'Download badge',
    url: 'https://s3.eu-west-1.amazonaws.com/files.partnermarketing.com/irp/Module-2-TRAINING-3f5c197b616745dc8d704598f5babbb0.zip'
  },
  product: {
    code: LEVEL_CODES.PRODUCT,
    level: 'III',
    title: 'ProSkills Product',
    description: 'Download your ProSkills Product badge.',
    action: 'Download badge',
    url: 'https://s3.eu-west-1.amazonaws.com/files.partnermarketing.com/irp/Module-3-PRODUCT-49f70b45fbc64ffbbd25e66483f0aa6e.zip'
  },
};

export const PRIZE_DRAWS = {
  june: {
    code: 'june',
    title: 'June',
    startDate: '2023-06-01 00:00:00',
    lastOptInDate: '2023-06-30 23:59:59',
    checkDate: '2023-06-30 23:59:59',
  },
  july: {
    code: 'july',
    title: 'July',
    startDate: '2023-06-01 00:00:00',
    lastOptInDate: '2023-07-31 23:59:59',
    checkDate: '2023-07-31 23:59:59',
  },
};

export const LEVELS = {
  marketing: {
    lastOptInDate: '2023-03-28 23:59:59',
    checkDate: '2023-03-31 00:00:00',
    active: true,
    code: LEVEL_CODES.MARKETING,
    icon: 'heart',
    stepDescription: 'Put the Pro into ProAdvisor by completing our 3 ProSkills modules. The next module is unlocked when you’ve completed the previous one.',
    steps: [],
    title: 'Marketing',
    welcomeBoxDescription: 'Build your brand and attract new clients by accessing this new and exclusive content from My Marketing Hub. It’ll help take your practice to the next level…',
    subtitle: 'Put the Pro into ProAdvisor by completing our 3 ProSkills modules. The next module is unlocked when you’ve completed the previous one.',
    description: 'Once you’ve completed all activities in each module, you’ll earn your ProSkills badge which you can promote to your clients. Plus, you can be entered into a prize draw to win some exciting prizes.',
    levelPageSubtitle: 'Download these marketing guides to grow your expertise. They’ll help you build your brand, reach new clients and be seen as the accountancy authority they want to work with.',
    subtitle2: 'Want to access more free marketing resources for your practice and clients?',
    subtitleLink: 'https://quickbooks.partnermarketing.com',
    subtitleLinkTitle: 'Visit ProAdvisor My Marketing Hub.',
    activitiesTip: '<a href="https://support.partnermarketing.com/hc/en-us/requests/new" target="_blank">Need help?</a> Contact us if you experience any technical problems or issues with your Marketing module stars appearing.',
    activities: [
      {
        title: 'Download the “How to get the most out of co-branding” guide',
        description: 'See how co-branding with QuickBooks can help take your practice to the next level.',
        action: 'Download guide',
        assetId: '2507',
      },
      {
        title: 'Download the “Turn likes into loyal online followers” guide',
        description: 'Discover ways to supercharge your social media.',
        action: 'Download guide',
        assetId: '2514',
      },
      {
        title: 'Download the “SEO 101” guide',
        description: 'Learn more about SEO, and how this can increase your organic web presence.',
        action: 'Download guide',
        assetId: '2520',
      },
    ]
  },
  training: {
    startDate: '2023-03-29 00:00:00',
    lastOptInDate: '2023-05-09 23:59:59',
    checkDate: '2023-05-12 00:00:00',
    active: true,
    code: LEVEL_CODES.TRAINING,
    icon: 'glasses',
    stepDescription: 'Put the Pro into ProAdvisor by completing our 3 ProSkills modules. The next module is unlocked when you’ve completed the previous one.',
    title: 'Training',
    welcomeBoxDescription: 'Build your brand and attract new clients by accessing this new and exclusive content from My Marketing Hub. It’ll help take your practice to the next level…',
    subtitle: 'Watch these training sessions to advance your QuickBooks knowledge, and build even greater trust and confidence with your clients.',
    description: 'Watch all three videos to be eligible to enter the prize draw.',
    levelPageSubtitle: 'Watch these training sessions to advance your QuickBooks knowledge, and build even greater trust and confidence with your clients.',
    levelPageDescription: 'Watch all three videos to be eligible to enter the prize draw.',
    activitiesTip: '<a href="https://support.partnermarketing.com/hc/en-us/requests/new" target="_blank">Need help?</a> Contact us if you experience any technical problems or issues with your Training module stars appearing.',
    activities: [
      {
        title: 'QuickBooks Online Accountant – Books review',
        description: 'Learn how to integrate month-end review seamlessly into your firm’s way of working and benefit from greater consistency and time savings.',
        action: 'Watch video',
        url: 'https://player.vimeo.com/progressive_redirect/playback/862396284/rendition/720p/file.mp4?loc=external&log_user=0&signature=a3a964a62723b8787a63634640bd74e4cf9212ed86f436f02e1f99bb0ddb90be',
        isVideo: true
      },
      {
        title: 'QuickBooks Online Accountant – Workpapers',
        description: 'Learn more about how you can make year-end a breeze. See how you can pull bookkeeping data and work papers into one place for smoother workflows.',
        action: 'Watch video',
        url: 'https://player.vimeo.com/progressive_redirect/playback/862396810/rendition/720p/file.mp4?loc=external&log_user=0&signature=5b12d8202164e8ef5de53ec7c9d84de7c1f75e6544b86ca21a21823aa2a7795b',
        isVideo: true
      },
      {
        title: 'QuickBooks Standard & Advanced Payroll overview',
        description: 'Learn more about our QuickBooks Payroll solutions, and discover how you can easily run payroll for multiple customers with complex payroll needs.',
        action: 'Watch video',
        url: 'https://player.vimeo.com/progressive_redirect/playback/862397251/rendition/720p/file.mp4?loc=external&log_user=0&signature=8bc555ad397ad2a0d85698d34d9d15dfc81f0157a49cff4152170e24b065a1b5',
        isVideo: true
      },
    ]
  },
  product: {
    startDate: '2023-04-26 00:00:00',
    lastOptInDate: '2023-05-23 23:59:59',
    checkDate: '2023-06-01 00:00:00',
    active: true,
    code: LEVEL_CODES.PRODUCT,
    icon: 'product',
    stepDescription: 'Explore enhanced features in QuickBooks Online Accountant with Workpapers and our Books review tool - and see how you can make year-end a breeze.',
    title: 'Product',
    welcomeBoxDescription: 'Build your brand and attract new clients by accessing this new and exclusive content from My Marketing Hub. It’ll help take your practice to the next level…',
    subtitle: 'Explore enhanced features in QuickBooks Online Accountant with Workpapers and our Books review tool - and see how you can make year-end a breeze.',
    description: 'Watch these short videos hosted by a QuickBooks product expert, and log in to <a href="https://quickbooks-proadvisor-proskills.com" target="_blank">QuickBooks Online Accountant</a> to explore these features and collect your stars.',
    levelPageSubtitle: 'Explore enhanced features in QuickBooks Online Accountant with Workpapers and our Books review tool - and see how you can make year-end a breeze.',
    levelPageDescription: 'Watch these short videos hosted by a QuickBooks product expert, and log in to <a href="https://quickbooks-proadvisor-proskills.com" target="_blank">QuickBooks Online Accountant</a> to explore these features and collect your stars.',
    activitiesTip: '<a href="https://support.partnermarketing.com/hc/en-us/requests/new" target="_blank">Need help?</a> Contact us if you experience any technical problems or issues with your Product module stars appearing.',
    activities: [
      {
        title: 'Books review tool',
        description: 'Access easy to use workflows to manage month-end tasks and your clients’ books.',
        action: 'Watch video',
        url: 'https://player.vimeo.com/progressive_redirect/playback/819529727/rendition/720p/file.mp4?loc=external&signature=33adc28aa19e9091721320fbce63c9e58ef56b010a4d9f4b47339d69c36d7f02',
        isVideo: true
      },
      {
        title: 'Workpapers',
        description: 'Save time pulling bookkeeping data and work papers into one place.',
        action: 'Watch video',
        url: 'https://player.vimeo.com/progressive_redirect/playback/819529679/rendition/720p/file.mp4?loc=external&signature=d6584b9cf02cf50d449e9ca63be06ddfa0e463f26b18ef5b9b9b24872cc92c89',
        isVideo: true
      },
      {
        title: 'Survey',
        description: 'Complete a short survey and let us know your thoughts on ProAdvisor ProSkills.',
        action: 'Give feedback',
        url: 'survey',
        isSurvey: true
      },
    ]
  },
};