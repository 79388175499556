import React, {FC} from "react";
import {OPT_IN_STATUS} from "../../Constants";
import useUser from "../../../../hooks/getUser";
import moment from "moment";
import PrizeDrawBoxContent from "../PrizeDrawBoxContent/PrizeDrawBoxContent";

interface IMonthPrizeDrawItemBoxProps {
  code: string;
  title: string;
};
const MonthPrizeDrawItemBox: FC<IMonthPrizeDrawItemBoxProps> = ({code, title}) => {
  const {areAllLevelsCompleted, getPrizeDrawConfig, getPrizeDrawOptInStatus, hasWonPrizeDraw} = useUser();

  const allLevelsCompleted = areAllLevelsCompleted();
  const config = getPrizeDrawConfig(code);
  const optInStarted = allLevelsCompleted && (!config?.startDate || moment().diff(config?.startDate) >= 0);
  const checkDateActive = !config?.checkDate || new Date() < new Date(config?.checkDate);
  const looseStyle = !checkDateActive;
  const optInDateActive = !config?.lastOptInDate || new Date() < new Date(config?.lastOptInDate);
  const drawOptInStatus = getPrizeDrawOptInStatus(code);
  const isOptedIn = drawOptInStatus === OPT_IN_STATUS.YES;
  const hasWon = hasWonPrizeDraw(code);

  return (
    <PrizeDrawBoxContent code={code}
      optInStarted={optInStarted}
      looseStyle={looseStyle}
      isOptedIn={isOptedIn}
      optInDateActive={optInDateActive}
      title={title}
      hasWon={hasWon}
      checkDateActive={checkDateActive}
      config={config}
      />
  );
};

export default MonthPrizeDrawItemBox;
