import { useCallback, useState } from 'react';

interface IUseMobileCover {
  opened: boolean;
  fixedWidth: boolean;
  open: () => void;
  close: () => void;
  fixWidth: () => void;
}

const useMobileCover = (initialState = true): IUseMobileCover => {
  const [opened, setOpened] = useState(initialState);
  const [fixedWidth, setFixedWidth] = useState(false);
  const open = useCallback(() => setOpened(true), [setOpened]);
  const fixWidth = useCallback(() => setFixedWidth(true), [setFixedWidth]);
  const close = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  return {opened, fixedWidth, open, close, fixWidth};
};

export default useMobileCover;
