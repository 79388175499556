import React, {FC} from "react";
import cn from "classnames";
import styles from "./Status.module.scss";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import getUser from "../../hooks/getUser";

interface IStatusProps {
  percent: number;
  darkMode: boolean;
}

const Status: FC<IStatusProps> = ({percent, darkMode}) => {
  const { getTotalConfigActivities, getTotalCompletedActivities } = getUser();
  const allActivities = getTotalConfigActivities();
  const completedActivities = getTotalCompletedActivities();
  return (
    <div className={cn(styles.wrapper, darkMode && styles.darkMode)}>
      <ProgressCircle percent={percent} darkMode={darkMode} />
      <div className={styles.value}>
        <span className={styles.label}>STATUS</span>
          <span className={styles.percent}>{`${completedActivities}/${allActivities}`}<span>completed</span></span>
      </div>
    </div>
  );
};

export default Status;