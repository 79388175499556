import React, {FC, useContext} from "react";
import styles from "./Welcome.module.scss";
import WelcomeBox from "../WelcomeBox/WelcomeBox";
import currentLevelContext from "../../../../context/currentLevelContext";
import useUser from "../../../../hooks/getUser";
import {STATUSES} from "../../Constants";

const Welcome: FC  = () => {
  const {currentLevelCode} = useContext(currentLevelContext);
  const {getUsername, getLevelConfig, getLevelStatus, getTotalCompletedActivities} = useUser();
  const status = getLevelStatus(currentLevelCode);
  const config = getLevelConfig(currentLevelCode);
  const userName = getUsername();
  const totalCompletedActivities = getTotalCompletedActivities();

  return (
    <>
      {!!userName && (
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{totalCompletedActivities > 0 ? config?.title : `Welcome, ${userName}`}</h1>
          {config && (
            <div className={styles.texts}>
              <p className={styles.subtitle}>{status !== STATUSES.PENDING ? config.subtitle : config.stepDescription}</p>
              <p className={styles.description} dangerouslySetInnerHTML={{ __html: config.description }}></p>
              {config.steps && status !== STATUSES.COMPLETED && (
                <ol>
                  {config.steps.map((step, i) => (
                    <li key={`welcome-step-${i}`}>{step}</li>
                  ))}
                </ol>
              )}
            </div>
          )}
          <WelcomeBox />
        </div>
      )}
    </>
  )
};

export default Welcome;