import React, {FC, useEffect, useState} from "react";
import cn from "classnames";
import styles from "./LoadingContainer.module.scss";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import Icon from "../Icon/Icon";

library.add(
  faSpinner
);

interface ILoadingContainerProps {
  children: React.ReactNode | undefined;
  className?: string;
  loading: boolean;
}
const LoadingContainer: FC<ILoadingContainerProps> = ({ className, loading: propLoading, children }) => {
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    setInitialRender(false);
  }, []);

  const loading = propLoading || initialRender;

  return (
    <div
      className={cn(
        styles.loadingContainer,
        loading && styles.loading,
      )}
    >
      {
        !!loading && (
          <div className={styles.spinnerWrapper}>
            <div className={styles.spinner}>
              <Icon kind="loading" className={styles.spin} />
            </div>
          </div>
        )
      }
      {!loading && children}
    </div>
  );
}

export default LoadingContainer;
