import {useFetch} from "use-http";
import getApiUrl from "../../utils/getApiUrl";
import {useContext} from "react";
import currentLevelContext from "../../context/currentLevelContext";

export type SurveyFormValues = {
  recommendProSkills: string,
}

const useSurvey = () => {
  const { currentLevelCode } = useContext(currentLevelContext);
  const { post: postSurveyRequest, response: postSurveyResponse } = useFetch<number>(
    getApiUrl(
      "partnermarketing_irp_survey",
      {
        code: currentLevelCode
      },
      true
    ), {}
  );

  const onSubmit = async (values: SurveyFormValues, callback: () => void) => {
    await postSurveyRequest({data: values});
    if (postSurveyResponse.ok) {
      callback();
    }
  };

  return {
    initialValues: {
      recommendProSkills: ''
    } as SurveyFormValues,
    onSubmit
  }
};

export default useSurvey;
