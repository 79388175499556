import React, {FC, useContext} from "react";
import styles from "./WelcomeBox.module.scss";
import Star from "../../../../components/Star/Star";
import glasses from "./icon-glasses.png";
import heart from "./icon-marketing.png";
import product from "./icon-product.png";
import iconRosette from "./icon-rosette.png";
import currentLevelContext from "../../../../context/currentLevelContext";
import Button from "../../../../components/Button/Button";
import useUser from "../../../../hooks/getUser";
import {STATUSES} from "../../Constants";
import {LEVEL_CODES} from "../../ProgramConfig";
import ProgressLine from "../ProgressLine/ProgressLine";

const WelcomeBox: FC = () => {
  const {open, currentLevelCode} = useContext(currentLevelContext);
  const { getLevelStatus, getLevelConfig,getLevelCompletedActivities } = useUser();
  const status = getLevelStatus(currentLevelCode);
  const config = getLevelConfig(currentLevelCode);
  const activitiesCount = config?.activities.length;
  const completedActivities = getLevelCompletedActivities(currentLevelCode);
  const completedActivitiesCount = completedActivities.length;
  let iconSrc = heart;
  switch (currentLevelCode) {
    case LEVEL_CODES.MARKETING:
      iconSrc = heart;
      break;
    case LEVEL_CODES.TRAINING:
      iconSrc = glasses;
      break;
    case LEVEL_CODES.PRODUCT:
      iconSrc = product;
      break;
  }
  const leverCompletedPercent = !!activitiesCount && activitiesCount > 0 ? completedActivitiesCount / activitiesCount * 100 : 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <div className={styles.icon}>
            <img src={iconSrc} alt={`${currentLevelCode} icon`} />
          </div>
          <div className={styles.title}>
            <p>MODULE</p>
            {config && (
              <h2>{config.title}</h2>
            )}
          </div>
        </div>
        <div className={styles.topRight}>
          <Button
            title={status === STATUSES.PENDING ? 'Get started' : `Revisit ${currentLevelCode}`}
            onClick={open}
          />
        </div>
      </div>
      {config && (
        <p className={styles.description}>{config.welcomeBoxDescription}</p>
      )}

      <ProgressLine percent={leverCompletedPercent} />

      <p className={styles.description}>Stars achieved:</p>

      <div className={styles.stars}>
        {config?.activities.map((_a, i) => (
            <Star key={`wb-star-${i}`} achieved={!!(_a.assetId || _a.url) && completedActivities.includes(_a.assetId || _a.url as string)} />
        ))}
        {/*{level && level.inPrizeDraw && level.inPrizeDraw === 'y' && (*/}
          <img src={iconRosette} alt={'Prize icon'} />
        {/*)}*/}
      </div>
    </div>
  )
};

export default WelcomeBox;