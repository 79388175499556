import React, {FC} from "react";
import cn from "classnames";
import styles from "./ProgressCircle.module.scss";

interface IProgressCircleProps {
  percent: number;
  darkMode: boolean;
}

const ProgressCircle: FC<IProgressCircleProps> = ({percent, darkMode}) => {
  return (
    <div className={cn(styles.setSize, styles.chartsContainer, darkMode && styles.darkMode)}>
      <div className={cn(styles.pieWrapper, styles[`progress${percent}`], styles.style2)}>
        <div className={styles.pie}>
          <div className={cn(styles.leftSide, styles.halfCircle)}></div>
          <div className={cn(styles.rightSide, styles.halfCircle)}></div>
        </div>
        <div className={cn(styles.shadow, !darkMode && styles.shadowDark)}></div>
      </div>
    </div>
  );
};

export default ProgressCircle;