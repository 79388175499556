import { useCallback, useState } from 'react';

interface IUseModal {
  modalOpened: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const useModal = (initialState = false): IUseModal => {
  const [modalOpened, setOpened] = useState(initialState);
  const openModal = useCallback(() => setOpened(true), [setOpened]);
  const closeModal = useCallback(() => setOpened(false), [setOpened]);

  return {modalOpened, openModal, closeModal};
};

export default useModal;
