import React, {FC, useContext} from "react";
import styles from "./Main.module.scss";
import Logo from "../../../../components/Logo/Logo";
import Welcome from "../Welcome/Welcome";
import currentLevelContext from "../../../../context/currentLevelContext";
import Level from "../Level/Level";
import Rewards from "../Rewards/Rewards";
import {DEFAULT_FE_HOST} from "../../../../constants";

const Main: FC = () => {
  const { opened, currentLevelCode } = useContext(currentLevelContext);
  const isRewards = currentLevelCode === 'rewards';

  return (
    <div className={styles.main}>
      <span className={styles.link}
        onClick={() => {
          window.localStorage.setItem('IRPTermsAccepted', '');
          window.location.href = DEFAULT_FE_HOST;
        }}
      >{`< Back to Home`}</span>
      <Logo type={'black'} />
      <div className={styles.wrapper}>
        {!isRewards && !opened && (<Welcome />)}
        {!isRewards && opened && (<Level />)}
        {isRewards && (<Rewards />)}
      </div>
    </div>
  )
};

export default Main;