import React, {FC} from "react";
import styles from "./TC.module.scss";

const TC: FC = () => {
  return (
    <div className={styles.wrapper}>
      <h2>Promoter:</h2>
      <p>Intuit Limited, (company No. 2679414), registered address 80 Victoria Street, London SW1E 5JL, England. (“Intuit”)</p>


      <h2>What is meant by “entrant” or “you”:</h2>
      <p>Entrant is an individual who has correctly completed his or her details in the Competition entry form (“EntryForm”) available on the Competition webpage at [<a target={"_blank"} rel={"noreferrer"} href={"https://quickbooks-proadvisor-proskills.com"}>http://www.quickbooks-ProAdvisor-ProSkills</a> [where the ProAdvisor <span className={styles.bold}>ProSkills campaign</span> page will be available] and that meets the eligibility criteria in the section below.</p>

      <h2>Eligibility:</h2>
      <p>This Competition is open only to legal residents of Great Britain (England, Scotland, Wales), Isle of Man and Channel Islands only, excluding Northern Ireland, who are eighteen (18) or older at the time of Entry and in order to be eligible to participate the entrant needs to be a UK QuickBooks ProAdvisor, with at least 1 or more QuickBooks paid licenses attached.</p>
      <p>You must be available, if winning the Prize, to participate in a video or photo shoot directed and recorded by Intuit or Intuit appointed third party, that features your business and the activities  performed pursuant to the Prize. You also need to agree that Intuit may use such video or photos or other Materials in accordance with the section titled Consent and Release below, including but not limited to, using the video, photos and any Materials across Intuit’s social channels and website for a reasonable time frame.]</p>
      <p>Employees of QuickBooks, its parent company, Intuit, their subsidiaries and affiliates (collectively “Intuit”), or of other companies associated with the promotion of the Contest, as well as the immediate family (spouse, parents, siblings, and children, and each of their respective spouses) and household members of each such employee are not eligible. Participation constitutes entrant’s full and unconditional agreement to these Official Rules and Sponsor’s decisions, which are final and binding in all matters related to the Competition. Winning a Prize is contingent upon fulfilling all requirements set forth herein.</p>

      <h2>How to participate:</h2>
      <p>In order to enter the Competition, the entrant must visit <a target={"_blank"} rel={"noreferrer"} href={"https://quickbooks-proadvisor-proskills.com"}>http://www.quickbooks-ProAdvisor-ProSkills</a> or log into the ProAdvisor campaign page from QuickBooks Online Accountant (QBOA) and opt in to be entered into the competition. Participants must complete the activities at each module of the ProSkill campaign to have a valid “Entry”, activities for each module are described and can be accessed <a target={"_blank"} rel={"noreferrer"} href={"https://quickbooks-proadvisor-proskills.com"}>here.</a></p>
      <p>There are three modules in this Competition (“module(s)”) and prizes can be won for each module separately in accordance with these Rules. For each module the  activities to be completed for an entry to be valid are as listed and accessible on the campaign page <a target={"_blank"} rel={"noreferrer"} href={"https://quickbooks-proadvisor-proskills.com"}>here.</a></p>
      <ol>
        <li>module 1: Marketing activities, relating to downloading the three assets specified in the ProSkill campaign page  from the QuickBooks ProAdvisor My Marketing Hub Portal. Once the activities listed in module 1 are completed within the timing specified below, the participant will be entered in the draw for the module 1 prizes listed below.</li>
        <li>module 2: Once completed the module 1 activities Participant may opt-in to perform the activities required to enter  the draw for the module 2 prizes by completing the specified training webinars as specified in the ProSkill campaign page</li>
        <li>module 3: Once completed the module 2 activities Participant may opt in to perform the activities required to enter the draw for module 3 prizes by logging in to QuickBooks Online Accountant and using the specified features and tools, and completing the ProSkills campaign survey.</li>
      </ol>
      <p>Upon completion of each activity (marketing module, training module, and product log in module), ProAdvisors participating in the programme will be awarded a virtual “star” on the campaign webpage. Upon completing each of the outlined three modules (as above), ProAdvisors participating in the campaign will be entered  into the relevant   draw for the  prize pertaining to the module achieved.</p>

      <h2>Entrant Information:</h2>
        <p>All personal data shared by the entrants will be collected and processed in accordance with Intuit’s privacy statement available at <a target={"_blank"} rel={"noreferrer"} href={"https://www.intuit.com/privacy/statement"}>Intuit Global Privacy Statement here</a>. By entering the Competition entrants acknowledge and accept that the personal data shared by the entrants may be used in other materials related to the Competition; this includes but is not limited to case studies that could be published on Intuit’s website, as well as the other print and online media. Other than as provided herein, no personal information will be made public without the entrant’s prior knowledge or approval.</p>
      <p>The Promoter is not responsible for any incomplete or incorrect entries which will prevent the entrant from being included in the draw. Only one entry per entrant will be counted, and the Promoter holds the right to disqualify any entrants suspected of entering incorrect or false personal information, or when the promoter has reasons to believe that the entrant has tried to gain multiple entries to the Competition.</p>

      <h2>Timing:</h2>
      <p>Eligible entries will open on <span className={styles.bold}>8 June 2023</span> at 10.00am GMT (“Opening Date”) and all participants must complete the modules by 31 July 2023.</p>
      <p>There are 30 prizes to be won. This prize is a QuickBooks giveaway or voucher to a small business supplier or QuickBooks branded giveaways.</p>
      <p>Entries received by the Promoter outside the Competition Period will not be accepted and will not participate in the draw. Intuit reserves the right to amend the Competition end date at any time.</p>

      <ul>
        <li>There are no cash alternatives or alternative prizes available.</li>
        <li>The Promoter reserves the right to substitute the prize for one of equal or greater value if the designated prize should become unavailable for any reason. The prize cannot be transferred to another person or business. No part of the prize is exchangeable for cash or any other prize.</li>
        <li>Prizes must be claimed within 60 days of being notified by email.</li>
      </ul>
      <p></p>

      <h2>Selecting and notifying the Winner:</h2>
      <p>The winners will be selected in accordance with this Official Rules on the last day of the month by random draw and will be notified of the winning by an email at the address as applicable supplied at the time of entering the competition as they signed up to the ProAdvisor campaign page, or as linked to their QBOA account. The name and county of the winner will be made available upon request and by entering the Competition you agree that Intuit will be entitled to share such information.</p>

      <h2>Consent and release:</h2>
      <p>Entrant understands and agrees that his/her participation to the Competition may be recorded via photograph, audio, or other audio or video recording media, and/or by videotape, any or all of which may be recorded by Intuit and with all rights vesting in Intuit, regardless of whether such photographs, audio, or videotaped recordings are created by Intuit.</p>
      <p>NOW, THEREFORE, in consideration of the premises and the mutual covenants set forth herein and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged,  Entrant hereby grant Intuit, and its subsidiaries, affiliates, successors, and assigns, and to other persons as Intuit may designate from time to time, the absolute right and permission to reproduce, use, adopt, modify, excerpt, distribute and display: (1) Entrant likeness and any photographic, audio and/or video recordings of Entrant and the activities happening in the Entrant premises and any other activity happening in relation to the Prize; and, (2) ideas and comments shared in relation to any photographic, audio and/or video recordings and any content created by Intuit during the recordings, either alone or accompanied by other material, (all together the “Materials”) in any manner and in any media, throughout the world, in perpetuity, for the purpose of advertising,  promoting and publicizing Intuit and/or its Products and/or Services, including but not limited sharing it on social media.  The Entrant irrevocably and unconditionally waive all moral rights (if any) vested in the Entrant in respect of the Materials. The Materials may be used in whole or in part and may be paraphrased, amplified, shortened and/or put into conversational form provided the general sense is not changed.</p>
      <p>Entrant waives any right that he/she may have to inspect or approve the finished Materials and hereby discharge Intuit from any liability (including, without limitation, for invasion of privacy or defamation) for making, editing or using such recording.  Entrant agrees that he/she will not hold Intuit responsible for any liability resulting from the use of my name, initials, likenesses, photograph(s) and audio-video recordings in accordance with this consent and release, including what might be deemed to be misrepresentation of Entrant, his/her character or person due to distortion, optical illusion or faulty reproduction which may occur in the finished Materials.</p>

      <h2>General Conditions:</h2>
      <p>By participating, you agree:</p>
      <p className={styles.glue}>(a) to abide by these Official Rules and decisions of Intuit, which shall be final and binding in all respects relating to this Promotion;</p>
      <p className={styles.glue}>(b) to release, discharge and hold harmless Intuit, and its parent, affiliates, subsidiaries, and advertising and promotion agencies, and the respective officers, directors, shareholders, employees, agents and representatives of the foregoing (collectively, “Released Parties”) from any and all injuries, liability, losses and damages of any kind to persons, or property resulting, in whole or in part, directly or indirectly, from your participation in the Competition or any related activity or the acceptance, possession, use or misuse of any awarded prize.</p>
      <p className={styles.glue}>(c) not to release any publicity or other materials on your own or through someone else regarding participation in the Competition without the prior consent of Intuit.</p>
      <p className={styles.glue}>(d) that if there is a dispute as to the identity of an online entrant, the prize will be awarded to the authorized account holder of the email address and entrant must comply with these Official Rules. The “authorized account holder” is defined as the natural person to whom the email address is assigned by an Internet service provider, online service provider or other organization (e.g., business, educational institution, etc.) that is responsible for assigning email addresses for the domain associated with the submitted email address.</p>
      <p className={styles.glue}>(e) that Released Parties are not responsible for any errors or difficulties of any kind or otherwise relating to the Competition, including, without limitation, errors or difficulties which may occur in connection with the administration of the Competition , the processing or judging of entries, the announcement of the prizes, or the incorrect uploading of the entry or in any Competition related materials.</p>
      <p className={styles.glue}>(f) Intuit reserves the right to disqualify any person and void their associated Entries in the Competition Intuit determines there is tampering with the Competition or that person is acting in an unsportsmanlike or disruptive manner. If Intuit believes any portion of the Competition has been compromised by any means, which impairs the administration, security, fairness or proper play, or submission of Entries, Intuit reserves the right to suspend, modify or terminate the Competition and, if terminated, at its discretion, select the potential winner(s) from all eligible, non-suspect Entries received prior to action taken using the judging procedure outlined above.</p>
      <p className={styles.glue}>The UK ProAdvisor Terms & Conditions, and the Global ProAdvisor Terms and Conditions</p>
      <ul>
        <li><a href={"https://quickbooks.intuit.com/oicms/var/pap-terms/"} target={"_blank"} rel={"noreferrer"}>https://quickbooks.intuit.com/oicms/var/pap-terms/</a></li>
        <li><a href={"https://quickbooks.intuit.com/uk/legal/proadvisor/"} target={"_blank"} rel={"noreferrer"}>https://quickbooks.intuit.com/uk/legal/proadvisor/</a></li>
      </ul>
      <p>Will apply to the activities under this Competition in the event of any inconsistency between such  terms and the Official Rules this Official Rules will prevail in relation  to the Competition.</p>

      <h2>Intuit reserves the right to alter the qualifying activities at each module during the course of the ProSkills campaign.</h2>
        <p>By participating in this <span className={styles.bold}>ProSkills campaign</span> you agree to receive email communications regarding the ProSkills campaign, or your participation in the campaign, from Intuit or the campaign platform manager, Partner Marketing, Twogether Creative Limited, Intuit reserves the right to modify or withdraw the ProSkills campaign at any time.</p>

      <h2>Governing Law/Jurisdiction:</h2>
      <p>All issues and questions concerning these Official Rules or the rights and obligations of participants or Intuit in connection with the Competition shall be governed by the laws of England and Wales, without giving any effect to any choice of law or conflict of law rules.  Any dispute shall be subject to the jurisdiction of the English Courts.</p>

      <p><span className={styles.bold}>Severability:</span> If any provision(s) of these Official Rules are held to be invalid or unenforceable, all remaining provisions hereof will remain in full force and effect.</p>
      <p>Suppliers of prizes as part of this competition are not the not sponsor of this promotion or campaign. Gift Cards and Gift Voucher ("GCs") may be redeemed solely on the relevant supplier websites toward the purchase of eligible products listed on the supplier website. GC cannot be reloaded, resold, transferred for value, redeemed for cash or applied to any other account and expire after 1 from the date of issuance. Terms and conditions of the GCs apply as stipulated by the supplier website or terms and conditions. The suppliers of the GC are not responsible if a GC is lost or stolen, destroyed or used without permission.</p>
      <p>If this Competition is communicated, promoted and/or administer via a social media platform including but not limited to FaceBook (Meta), Twitter, Instagram (Meta) and Linkedin (“Social Media Platform”), by entering this Competition entrants acknowledge and agree that (i) this Competition is in no way sponsored, endorsed or administered by, or associated with the applicable Social Media Platform; (ii)  for the purpose of this Competition the Social Media Platform is considered a Released Party;  (iii) Multiple entries in a single day will not be accepted; (iv)  Anyone found to use multiple accounts to enter will be ineligible.</p>
    </div>
  );
};

export default TC;