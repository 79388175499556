import React, {FC, useCallback, useContext} from "react";
import styles from "./ActivityBox.module.scss";
import heart from './icon-heart.png';
import glasses from './icon-glasses.png';
import product from './icon-product.png';
import star from './icon-star.png';
import download from './icon-download.png';
import prize from './icon-rosette.png';
import getApiUrl from "../../../../utils/getApiUrl";
import Button from "../../../../components/Button/Button";
import cn from "classnames";
import isFunction from "lodash/isFunction";
import currentLevelContext from "../../../../context/currentLevelContext";
import useUser from "../../../../hooks/getUser";
import {CachePolicies, useFetch} from "use-http";
import {wait} from "../../../../utils/helper";
import {OneTimeTokenType} from "../../../../types";
import {LEVEL_CODES} from "../../ProgramConfig";
import useModal from "../../../../hooks/useModal";
import Modal from "../../../../components/Modal/Modal";
import Survey from "../../../../components/Survey/Survey";
import {Formik} from "formik";
import useSurvey from "../../../../components/Survey/useSurvey";
import {object, string} from "yup";
import useVideo from "../../../../hooks/useVideo";

interface IActivityProps {
  icon: string;
  levelTitle: string;
  title: string;
  description: string;
  action: string;
  assetId?: string,
  url?: string;
  actionCallback?: (a: string) => void;
  className?: string;
  isSurvey?: boolean;
  isVideo?: boolean;
  isDisabled?: boolean;
}

const Activity: FC<IActivityProps> = ({levelTitle, icon, title, description, action, assetId, url, actionCallback, className, isSurvey, isDisabled, isVideo}) => {
  const { get, response } = useFetch<OneTimeTokenType>(getApiUrl("partnermarketing_user_get_one_time_auth_token"), {
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const { getLevelData, getUser } = useUser();
  const { currentLevelCode } = useContext(currentLevelContext);
  const {modalOpened: surveyModalOpened, openModal: openSurveyModal, closeModal: closeSurveyModal} = useModal(false);
  const {modalOpened: videoModalOpened, openModal: openVideoModal, closeModal: closeVideoModal} = useModal(false);
  const {onPlay: onVideoPlay, videoRef} = useVideo();

  const { post: trackLinkVisitRequest, response: trackLinkVisitResponse } = useFetch<number>(
    getApiUrl(
      "partnermarketing_irp_link_visit",
      {
        code: currentLevelCode,
        url
      },
      true
    ), {}
  );
  const {
    initialValues,
    onSubmit
  } = useSurvey();
  const SCHEMA = () =>
    object().shape({
      recommendProSkills: string().required('Required'),
      recommendProgramme: string().required('Required'),
      learn: string().required('Required'),
      rateModule1: string().required('Required'),
      rateModule2: string().required('Required'),
      rateModule3: string().required('Required'),
      recommendedProSkillsComment: string().nullable(),
      recommendProgrammeComment: string().nullable(),
      mostValuable: string().required('Required'),
      howImprove: string().required('Required'),
    });
  const surveyCallback = async () => {
    closeSurveyModal();
    if (isFunction(actionCallback)) {
      actionCallback('survey');
    }
  }

  const videoPlayCallback = async (url: string) => {
    if (isFunction(actionCallback)) {
      actionCallback(url);
    }
  }

  const redirect = useCallback(async () => {
    if (url) {
      await trackLinkVisitRequest();
      if (trackLinkVisitResponse.ok) {
        await wait(1000).then(() => {
          getUser();
          window.open(url, '_blank');
        });
      }
    }

    if (assetId) {
      const assetUrl = getApiUrl("partnermarketing_irp_track_asset_download", {id: assetId}, true);
      const data = await get();
      if (response.ok) {
        await wait(1000).then(() => {
          window.location.href = assetUrl + data.urlQueryString;
        });
      }
    }

    await wait(1000);
    return 'ok';
  }, [get, response, assetId, url, trackLinkVisitRequest, trackLinkVisitResponse, getUser]);

  const level = getLevelData(currentLevelCode);
  const activityId = assetId || url;
  const isCompleted = !!activityId && !!level && level.completedActivities.includes(activityId);
  const isPending = !isCompleted && !!url && !!level && level.pendingActivities?.includes(url);

  let iconSrc = star;
  if (!isCompleted) {
    switch (icon) {
      case "heart":
        iconSrc = heart;
        break;
      case "glasses":
        iconSrc = glasses;
        break;
      case "product":
        iconSrc = product;
        break;
      case "prize":
        iconSrc = prize;
        break;
    };
  };

  return (
    <div className={cn(styles.wrapper, !!className && className)}>
      <Modal
        isOpened={surveyModalOpened}
        title={'Survey'}
        cancel={() => closeSurveyModal()}
        wrapperClassName={styles.surveyModal}
        className={styles.surveyModalText}
      >
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, surveyCallback).then(() => {
              closeSurveyModal();
            });
            setSubmitting(false);
          }}
          validationSchema={SCHEMA()}
        >
          {({values, errors}) => (
            <Survey values={values} errors={errors} />
          )}
        </Formik>
      </Modal>
      <Modal
        isOpened={videoModalOpened}
        title={title}
        cancel={() => {
          if (videoRef?.current)
          videoRef.current.pause();
          closeVideoModal();
        }}
        wrapperClassName={styles.surveyModal}
        className={styles.videoModalText}
      >
        {!!url && (
          <video ref={videoRef} width="640" height="360" controls
            onPlay={() => onVideoPlay(url, videoPlayCallback)}
          >
            <source src={url} type="video/mp4" />
          </video>
        )}
      </Modal>
      <div className={styles.texts}>
        <div className={styles.top}>
          <div>
            <img src={iconSrc} alt={'activity'} />
          </div>
          <div className={styles.title}>
            <span>{levelTitle.toUpperCase()}</span>
            <h1>{title}</h1>
          </div>
        </div>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.bottom}>
        <Button
          className={cn(styles.button, isPending && styles.pending, (isCompleted || isDisabled) && styles.inactive)}
          title={isPending ? 'Pending' : (isCompleted ? 'Completed' : action)}
          // @ts-ignore
          onClick={() => {
            if (isDisabled || (isCompleted && currentLevelCode === LEVEL_CODES.MARKETING)) {
              return;
            }
            if (isSurvey) {
              if (!isDisabled && !isCompleted) {
                openSurveyModal();
              }
              return;
            }

            if (isVideo) {
              openVideoModal();
              return;
            }

            redirect().then(() => {
              if (isFunction(actionCallback)) {
                actionCallback(assetId);
              }
            });
          }}
        />
        {isSurvey && isDisabled && (
          <div className={styles.tip}>Available after other activities completed.</div>
        )}
        {isCompleted && currentLevelCode === LEVEL_CODES.MARKETING && (
          <span className={styles.link} onClick={redirect}><img src={download} alt={'download'} /></span>
        )}
      </div>
    </div>
  )
};

export default Activity;