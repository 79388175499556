import React, {FC} from "react";
import cn from "classnames";
import styles from "./MobileDeviceCover.module.scss";
import Footer from "../Footer/Footer";
import Logo from "../Logo/Logo";

interface IMobileDeviceCover {
  isOpened: boolean;
  close: () => void;
  fixWidth: () => void;
}

const isMobile = () => {
  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;

  return regexp.test(details);
}
const MobileDeviceCover: FC<IMobileDeviceCover> = ({isOpened, close, fixWidth}) => {
  return (
    <div className={cn(styles.wrapper, isOpened && styles.open)}>
      <div className={cn(styles.logo, styles.part)}>
        <Logo type={'black'} />
      </div>
      <div className={cn(styles.top, styles.part)}>
        <h1>This site is optimized for desktop use.</h1>
        <p>Please <span className={styles.link} onClick={() => {
          if (isMobile()) {
            fixWidth();
          }
          close();
        }}>click here</span> for the desktop version.</p>
      </div>
      <div className={cn(styles.bottom, styles.part)}>
        <Footer />
      </div>
    </div>
  );
}

export default MobileDeviceCover;