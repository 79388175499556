import React, {FC} from "react";
import styles from "./PrizeDrawBoxContent.module.scss";
import {WIN_STATUS} from "../../Constants";
import {wait} from "../../../../utils/helper";
import useUser from "../../../../hooks/getUser";
import {useFetch} from "use-http";
import getApiUrl from "../../../../utils/getApiUrl";
import cn from "classnames";
import Modal from "../../../../components/Modal/Modal";
import TC from "../../../../components/TC/TC";
import Button from "../../../../components/Button/Button";
import useLevel from "../../../../hooks/useLevel";
import useModal from "../../../../hooks/useModal";
import prize from './icon-rosette.png';
import prizeDisabled from './icon-rosette-disabled.png';
import prizePending from './icon-rosette-pending.png';
import prizeWin from './icon-rosette-win.png';
import moment from "moment";
import {ILevel, IPrizeDraw} from "../../../../types";

interface IPrizeDrawBoxContent {
  code: string;
  optInStarted: boolean;
  looseStyle: boolean;
  isOptedIn: boolean;
  optInDateActive: boolean;
  title: string;
  hasWon: string;
  checkDateActive: boolean;
  config: IPrizeDraw | ILevel | null;
};
const PrizeDrawBoxContent: FC<IPrizeDrawBoxContent> = ({code, optInStarted, looseStyle, isOptedIn, optInDateActive, title, hasWon, checkDateActive, config}) => {
  const {getUser} = useUser();
  const {optedIn, optIn} = useLevel();
  const {modalOpened: tcModalOpened, openModal: openTcModal, closeModal: closeTcModal} = useModal(false);
  const { post: optInApiCallTrue, response: optInTrueApiResponse } = useFetch<number>(
    getApiUrl(
      "partnermarketing_irp_opt_in_prize_draw",
      {
        code: code,
        status: true
      },
      true
    ), {}
  );
  let iconSrc = prize;
  if (!optInDateActive) {
    iconSrc = prizeDisabled;
  }
  if (isOptedIn) {
    switch (hasWon) {
      case WIN_STATUS.YES:
        iconSrc = prizeWin;
        break;
      case WIN_STATUS.UNKNOWN:
        iconSrc = prizePending;
        break;
      default:
        iconSrc = prizeDisabled;
        break;
    }

    if (hasWon === WIN_STATUS.UNKNOWN && !checkDateActive) {
      iconSrc = prizeDisabled;
    }
  }
  const boxInactive = !optInStarted;

  return (
    <div className={cn(
      styles.wrapper,
      boxInactive && styles.inactiveBadgeBox,
      looseStyle && styles.loose
    )}>
      <Modal
        isOpened={tcModalOpened}
        title={'OFFICIAL RULES OF QUICKBOOKS UK PROADVISOR PROSKILLS CAMPAIGN COMPETITION'}
        cancel={() => closeTcModal()}
        wrapperClassName={styles.tcModal}
        className={styles.modalText}
      >
        <TC />
      </Modal>
      <div className={styles.texts}>
        <div className={styles.top}>
          <div>
            <img src={iconSrc} alt={'icon rosette'} />
          </div>
          {!isOptedIn && optInDateActive && (
            <div className={styles.title}>
              <span>ENTER</span>
              <h1>{`${title} prize draw`}</h1>
            </div>
          )}
          {!isOptedIn && !optInDateActive && (
            <div className={styles.title}>
              <span>SORRY</span>
              <h1>{`You're too late to enter the ${title} prize draw`}</h1>
            </div>
          )}
          {isOptedIn && (<>
            {(hasWon === WIN_STATUS.NO || (hasWon === WIN_STATUS.UNKNOWN && !checkDateActive)) && (
              <div className={styles.title}>
                <span>SORRY</span>
                <h1>You haven’t been successful this time.</h1>
              </div>
            )}
            {hasWon === WIN_STATUS.UNKNOWN && checkDateActive && (
              <div className={styles.title}>
                <span>ENTERED</span>
                <h1>{`You have been entered into the ${title} prize draw`}</h1>
              </div>
            )}
            {hasWon === WIN_STATUS.YES && (
              <div className={styles.title}>
                <span>ENTERED</span>
                <h1>You have won a prize</h1>
              </div>
            )}
          </>)}
        </div>
        {!isOptedIn && optInDateActive && (
          <>
            <p className={styles.description}>{`Enter by ${moment(config?.lastOptInDate).format('DD/MM/YY')} to be in for a chance to win a prize.`}</p>
            <p className={styles.terms}>
              <input type="checkbox" id={`terms-${code}`} name="terms" disabled={boxInactive} checked={optedIn} onChange={() => optIn()} />
              <label htmlFor={`terms-${code}`}>I agree to the <span className={styles.link} onClick={(e) => {
                e.preventDefault();
                if (!optInStarted) {
                  return;
                }
                openTcModal();
              }}>Terms & Conditions</span></label>
            </p>
            {config?.lastOptInDate && (
              <span className={styles.hint}>{`Enter by ${moment(config.lastOptInDate).format('DD/MM/YY')} to be in for a chance to win a prize.`}</span>
            )}
          </>
        )}
        {isOptedIn && hasWon === WIN_STATUS.UNKNOWN && checkDateActive && (
          <p className={styles.description}>{`Check your email after ${moment(config?.checkDate).format('Do MMMM')} to see if you’ve won a prize…`}</p>
        )}
        {isOptedIn && hasWon === WIN_STATUS.YES && (
          <p className={styles.description}>You’ll be contacted by one of the team.</p>
        )}
      </div>
      {!isOptedIn && optInDateActive && (
        <div className={styles.bottom}>
          <Button
            className={cn(styles.button, !optedIn && styles.inactive)}
            title={'Enter now'}
            onClick={async () => {
              if (!optedIn) {
                return;
              }
              if (!optInDateActive || !optInStarted) {
                return;
              }

              await optInApiCallTrue();
              if (optInTrueApiResponse.ok) {
                await wait(1000).then(() => {
                  getUser();
                });
              } else {
                alert('An api error occured. Please try later.');
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PrizeDrawBoxContent;