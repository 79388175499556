import React, {FC, useContext} from "react";
import styles from "./Level.module.scss";
import currentLevelContext from "../../../../context/currentLevelContext";
import Activity from "../ActivityBox/ActivityBox";
import RewardsBox from "../RewardsBox/RewardsBox";
import Modal from "../../../../components/Modal/Modal";
import useModal from "../../../../hooks/useModal";
import useUser from "../../../../hooks/getUser";
import marketingBadge from './marketing-badge.png';
import trainingBadge from './training-badge.png';
import productBadge from './product-badge.png';
import rosette from './icon-rosette.png';
import {LEVEL_CODES} from "../../ProgramConfig";
import useLevel from "../../../../hooks/useLevel";
import getApiUrl from "../../../../utils/getApiUrl";
import {useFetch} from "use-http";
import {wait} from "../../../../utils/helper";
import {OPT_IN_STATUS} from "../../Constants";
import TC from "../../../../components/TC/TC";
import moment from "moment";

const Level: FC  = () => {
  const { setCurrentLevel } = useContext(currentLevelContext);
  const { currentLevelCode } = useContext(currentLevelContext);
  const {modalOpened, openModal, closeModal} = useModal(false);
  const {modalOpened: tcModalOpened, openModal: openTcModal, closeModal: closeTcModal} = useModal(false);
  const { getLevelConfig, getPrizeDrawConfig, getLevelCompletedActivities, getLevelSurveyActivities, getLevelDrawOptInStatus, getUser } = useUser();
  const {optedIn, optIn} = useLevel();
  const config = getLevelConfig(currentLevelCode);
  const levelCompletedActivities = getLevelCompletedActivities(currentLevelCode);
  const levelSurveyActivities = getLevelSurveyActivities(currentLevelCode);
  const optInStatus = getLevelDrawOptInStatus(currentLevelCode);
  const month = moment(new Date()).format('MMMM').toLowerCase();
  const prizeDrawConfig = getPrizeDrawConfig(month);
  const optInDateActive = !!prizeDrawConfig && (!prizeDrawConfig?.lastOptInDate || new Date() < new Date(prizeDrawConfig?.lastOptInDate));
  const areAllNotSurveyCompleted = ((config?.activities?.length || 0) - levelSurveyActivities.length) <= levelCompletedActivities.length
  let badge = marketingBadge;
  const showReward = LEVEL_CODES.PRODUCT === currentLevelCode;

  switch (currentLevelCode) {
    case LEVEL_CODES.TRAINING:
      badge = trainingBadge;
      break;
    case LEVEL_CODES.PRODUCT:
      badge = productBadge;
      break;
  }

  const { post: optInApiCallTrue, response: optInTrueApiResponse } = useFetch<number>(
    getApiUrl(
      "partnermarketing_irp_opt_in_prize_draw",
      {
        code: month,
        status: true
      },
      true
    ), {}
  );

  const { post: optInApiCallFalse, response: optInFalseApiResponse } = useFetch<number>(
    getApiUrl(
      "partnermarketing_irp_opt_in_prize_draw",
      {
        code: month,
        status: false
      },
      true
    ), {}
  );

  return (
    <div className={styles.wrapper}>
      <Modal
        isOpened={tcModalOpened}
        title={'OFFICIAL RULES OF QUICKBOOKS UK PROADVISOR PROSKILLS CAMPAIGN COMPETITION'}
        cancel={() => closeTcModal()}
        wrapperClassName={styles.tcModal}
        className={styles.modalText}
      >
        <TC />
      </Modal>

      <Modal
        isOpened={modalOpened}
        title={'Congratulations!'}
        text={showReward && optInStatus === OPT_IN_STATUS.YES ? 'You’ve been entered into the prize draw' : 'You’ve completed all of the activities in this module.'}
        confirmText={!showReward || optInStatus === OPT_IN_STATUS.YES ? `Go back to ${config?.title}` : `Enter me into the draw`}
        isConfirmButtonActive={optedIn || !showReward || optInStatus === OPT_IN_STATUS.YES}
        showCancelButton={showReward && optInStatus !== OPT_IN_STATUS.YES}
        cancelText={`Go to Rewards`}
        confirm={async () => {
          if (!showReward || optInStatus === OPT_IN_STATUS.YES) {
            closeModal();
            getUser();
            return;
          }

          if (!optedIn) {
            return;
          }

          await optInApiCallTrue();
          if (optInTrueApiResponse.ok) {
            await wait(1000).then(async () => {
              getUser();
              closeModal();
              await wait(1000).then(() => {
                setCurrentLevel('rewards');
              });
            });
          } else {
            alert('An api error occurred. Please try later.');
            closeModal();
          }
        }}
        cancel={async () => {
          if (!showReward || optInStatus === OPT_IN_STATUS.YES) {
            closeModal();
            getUser();
            return;
          }

          await optInApiCallFalse();
          if (optInFalseApiResponse.ok) {
            setCurrentLevel('rewards');
            closeModal();
            await wait(1000).then(() => {
              getUser();
            });

          } else {
            alert('An api error occurred. Please try later.');
            closeModal();
          }
        }}
        className={styles.modalWrapper}
      >
        {(!showReward || optInStatus !== OPT_IN_STATUS.YES) && (
          <>
            <div className={styles.modal}>
              <div>
                <img src={badge} alt={'Badge icon'} /><p>{`Download your ProSkills badges from the Rewards page.`}</p>
              </div>
              {showReward && (
              <div>
                <img src={rosette} alt={'Prize draw icon'} /><p>Enter into the prize draw. Keep an eye on your inbox to see if you’ve won a prize…</p>
              </div>
              )}
            </div>
            {showReward && (
            <p className={styles.terms}>
              <input type="checkbox" id="terms" name="terms" checked={optedIn} onChange={() => optIn()} />
              <label htmlFor="terms">I agree to the <span className={styles.link} onClick={(e) => {
                e.preventDefault();
                openTcModal();
              }}>Terms & Conditions</span></label>
            </p>
            )}
          </>
          )}
      </Modal>

      {config && (
        <>
        <div className={styles.top}>
          <h1 className={styles.title}>{config?.title}</h1>
          <p className={styles.subtitle}>{config.levelPageSubtitle}</p>
          {config.levelPageDescription && (
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: config.levelPageDescription }}></p>
          )}
          {!!config?.subtitle2 && (
            <p className={styles.subtitle}>{config?.subtitle2}
              {config?.subtitleLink && config?.subtitleLinkTitle && (
                <a href={config?.subtitleLink} rel="noreferrer" target={'_blank'}>{` ${config?.subtitleLinkTitle}`}</a>
              )}
            </p>
          )}
        </div>

        <div className={styles.actions}>
          <div className={styles.task}>
            <p>{config.task}</p>
            <span>{`${levelCompletedActivities.length} / ${config.activities.length} COMPLETE`}</span>
          </div>
          <div className={styles.activities}>
            {config.activities.map((activity, index) => (
              <Activity
                key={`activity-${index}`}
                levelTitle={config?.title}
                icon={config?.icon}
                title={activity.title}
                description={activity.description}
                action={activity.action}
                assetId={activity?.assetId}
                url={activity?.url}
                isSurvey={activity?.isSurvey}
                isVideo={activity?.isVideo}
                isDisabled={activity?.isSurvey && !areAllNotSurveyCompleted}
                actionCallback={async (activity: string) => {
                  await wait(1000).then(() => {
                    getUser();
                    const _levelCompletedActivities = getLevelCompletedActivities(currentLevelCode);
                    const addActivity = _levelCompletedActivities.includes(activity) ? 0 : 1;
                    const doneActivities = config?.activities.reduce((accum, current) => {
                      const source = current?.assetId ? current?.assetId : current?.url;
                      return accum + (!!source && _levelCompletedActivities.includes(source) ? 1 : 0);
                    }, 0);
                    const alreadyCompleted = doneActivities === config?.activities.length;

                    if (!alreadyCompleted && optInDateActive && ((doneActivities + addActivity) === config?.activities.length)) {
                      openModal();
                    }
                  })
                }}
              />
            ))}
          </div>
          {config?.activitiesTip && (
            <div className={styles.bottomText}>
              <p className={styles.description} dangerouslySetInnerHTML={{ __html: config.activitiesTip }}></p>
            </div>
          )}
          <RewardsBox />
        </div>
        </>
      )}
    </div>
  )
};

export default Level;