export const STATUSES = {
    SOON: 'coming_soon',
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed'
};

export const OPT_IN_STATUS = {
    UNKNOWN: 'u',
    YES: 'y',
    NO: 'n'
};

export const WIN_STATUS = {
    UNKNOWN: 'u',
    YES: 'y',
    NO: 'n'
};